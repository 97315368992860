:root {
  --base-url: $cdn_url + '';
  touch-action: pan-x pan-y;
  height: 100%;
  zoom: unset; }

/* nunito-regular - latin-ext_latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: local(""), url("https://ddzs4xvq8xxep.cloudfront.net/fonts/nunito-v16-latin-ext_latin-regular.woff2") format("woff2"), url("https://ddzs4xvq8xxep.cloudfront.net/fonts/nunito-v16-latin-ext_latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap; }

/* nunito-900 - latin-ext_latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  src: local(""), url("https://ddzs4xvq8xxep.cloudfront.net/fonts/nunito-v16-latin-ext_latin-900.woff2") format("woff2"), url("https://ddzs4xvq8xxep.cloudfront.net/fonts/nunito-v16-latin-ext_latin-900.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap; }

body {
  background: url("https://ddzs4xvq8xxep.cloudfront.net/arcade-background.webp");
  background-size: 300px 266px;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
  /* Make all elements unselectable */
  /* Override cursor style for buttons and other interactive elements */
  transition: background-color 0.8s ease-out; }
  body * {
    font-family: "Nunito", sans-serif;
    font-weight: 400;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
    cursor: default; }
  body a, body button, body input[type="button"], body input[type="submit"] {
    cursor: pointer;
    /* Change the cursor back to the pointer for buttons and links */ }
    body a *, body button *, body input[type="button"] *, body input[type="submit"] * {
      cursor: pointer;
      /* Change the cursor back to the pointer for buttons and links */ }
  body button:disabled {
    cursor: none; }
  body img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none; }
  body h1, body h2, body h3, body h4, body h5, body h6 {
    font-family: "Nunito", sans-serif;
    font-weight: 900; }

.q-layout__section--marginal {
  background: none transparent !important; }

h1 {
  margin-top: 0px;
  margin-bottom: 25px;
  line-height: 2rem; }

:root {
  --inner: white;
  --border: black;
  --accent: black; }

nav {
  display: grid;
  padding: 15px 50px 15px 50px;
  font-family: 'Montserrat', sans-serif; }
  nav.main-screen {
    grid-template-columns: 1fr 2fr; }
    nav.main-screen .in-mission {
      color: #ffffff;
      background-color: maroon;
      margin-right: 15px;
      padding: 12px;
      margin-top: -3px;
      border-radius: 5px; }
      nav.main-screen .in-mission span {
        font-weight: 900; }
    nav.main-screen .free-play {
      color: #ffffff;
      background-color: rgba(0, 94, 128, 0.5);
      padding: 12px;
      border-radius: 5px;
      margin-right: 15px;
      cursor: default;
      pointer-events: none; }
      nav.main-screen .free-play span {
        font-weight: 900; }
  nav.in-game {
    grid-template-columns: 1.2fr 2.4fr 1.6fr 2.4fr 3fr;
    grid-column-gap: 1rem;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)); }
    @media (max-width: 1024px) {
      nav.in-game {
        grid-template-columns: 2fr 2.4fr 1.6fr 2.4fr 3fr; } }
    nav.in-game .trial, nav.in-game .round {
      background-color: #000;
      border: 3px solid #fff;
      color: #fff;
      border-radius: 5px;
      padding: 3px 0px 3px 8px;
      display: flex;
      align-items: center;
      position: relative; }
      nav.in-game .trial span, nav.in-game .round span {
        position: relative;
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: 6px;
        background-color: #b3b3b3; }
        nav.in-game .trial span.active, nav.in-game .round span.active {
          background-color: #ffffff;
          border: 1px solid #ffffff; }
        nav.in-game .trial span.correct::before, nav.in-game .round span.correct::before {
          content: "";
          display: block;
          width: 24px;
          /* adjust as needed */
          height: 24px;
          background: url("https://ddzs4xvq8xxep.cloudfront.net/trial-check.svg") no-repeat;
          background-size: contain;
          position: absolute;
          top: 2px;
          left: -1px; }
        nav.in-game .trial span.incorrect::before, nav.in-game .round span.incorrect::before {
          content: "";
          display: block;
          width: 24px;
          /* adjust as needed */
          height: 24px;
          background: url("https://ddzs4xvq8xxep.cloudfront.net/trial-x.svg") no-repeat;
          background-size: contain;
          position: absolute;
          top: -2px;
          left: -2px; }
      nav.in-game .trial h4, nav.in-game .round h4 {
        display: inline-block;
        font-size: 16px;
        color: #fff;
        font-weight: 700;
        margin: 0px;
        margin-top: 12px;
        padding: 0px 10px 0px 0px;
        line-height: 20px;
        height: 100%;
        right: 0px;
        border: 0; }
  nav h4 {
    margin: 0px;
    font-size: 12px;
    color: #4A4A4A;
    text-align: left; }
    @media (min-width: 360px) {
      nav h4 {
        font-size: calc(12px + 8 * (100vw - 360px) / 840); } }
    @media (min-width: 1200px) {
      nav h4 {
        font-size: 20px; } }
  nav .hint-text h4 {
    color: #ffffff;
    font-size: 12px;
    margin-top: -4px; }
    @media (min-width: 360px) {
      nav .hint-text h4 {
        font-size: calc(12px + 5 * (100vw - 360px) / 840); } }
    @media (min-width: 1200px) {
      nav .hint-text h4 {
        font-size: 17px; } }
  nav .meta-navigation {
    justify-self: end; }
  nav button {
    position: relative;
    font-family: 'Montserrat', sans-serif;
    border: 0px;
    background: transparent;
    text-transform: uppercase;
    transition: opacity 0.2s ease-out;
    line-height: 25px;
    transition: all 0.3s ease; }
    nav button:hover {
      -webkit-transform: scale(1.1);
      transform: scale(1.1); }
    nav button:hover {
      cursor: pointer; }
    nav button.level {
      border-radius: 5px; }
      nav button.level:hover {
        pointer-events: none; }
    nav button.games, nav button.tickets, nav button.level {
      cursor: default;
      pointer-events: none;
      font-weight: 400;
      margin-right: 10px;
      font-size: 14px;
      color: #363636; }
      @media (min-width: 360px) {
        nav button.games, nav button.tickets, nav button.level {
          font-size: calc(14px + 2 * (100vw - 360px) / 840); } }
      @media (min-width: 1200px) {
        nav button.games, nav button.tickets, nav button.level {
          font-size: 16px; } }
      nav button.games span span, nav button.tickets span span, nav button.level span span {
        font-weight: 600;
        padding-right: 8px; }
    nav button.no-cursor :hover {
      cursor: none;
      pointer-events: none;
      transform: scale(1) !important; }
    nav button.logout {
      font-size: 14px;
      text-align: center; }
      @media (min-width: 360px) {
        nav button.logout {
          font-size: calc(14px + 2 * (100vw - 360px) / 840); } }
      @media (min-width: 1200px) {
        nav button.logout {
          font-size: 16px; } }
    nav button.mute {
      background: url("https://ddzs4xvq8xxep.cloudfront.net/icon-audio.svg") no-repeat;
      filter: invert(1);
      background-size: contain;
      text-indent: -9999px;
      overflow: hidden;
      width: 30px;
      height: 30px;
      margin-right: 0.8rem; }
      nav button.mute.isMuted {
        background: url("https://ddzs4xvq8xxep.cloudfront.net/icon-no-audio.svg") no-repeat;
        background-size: contain; }
  nav .tickets {
    font-size: 1.5em !important;
    border-radius: 0px;
    background: url("https://ddzs4xvq8xxep.cloudfront.net/ticket.webp") no-repeat;
    background-size: 100%;
    background-size: contain;
    text-align: center;
    min-width: 108px;
    min-height: 52px;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */ }
    nav .tickets:hover {
      cursor: default;
      opacity: 1; }
    nav .tickets:focus {
      outline: none; }
    nav .tickets span {
      padding: 0px !important;
      font-family: "NunitoBlack", sans-serif;
      color: #ffffff;
      display: inline-block;
      text-align: center; }

.task-hint.mission-mode {
  display: grid;
  grid-template-columns: 1fr 8fr;
  grid-gap: 2rem;
  position: absolute;
  top: 50vh;
  left: 50vw;
  margin-left: -20vw;
  /* Negative half of your width */
  z-index: 5000;
  background-color: #ffffff;
  color: #000000;
  padding: 25px 25px;
  min-width: 19vw;
  max-width: 50vw;
  border-radius: 5px;
  box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39); }
  .task-hint.mission-mode .task-hint__arrow svg {
    width: 120%;
    height: auto; }
  .task-hint.mission-mode h3 {
    margin-top: 0;
    margin-bottom: 1rem; }
  .task-hint.mission-mode .task-hint__text {
    font-size: 1.3em;
    font-weight: 400;
    margin: 0; }
    .task-hint.mission-mode .task-hint__text strong {
      font-weight: 700;
      text-transform: uppercase; }

.blur-content {
  filter: blur(3px);
  pointer-events: none; }

.level {
  background-color: #000;
  border: 3px solid #fff; }
  .level h4 {
    display: inline-block;
    color: #fff !important; }

.hints {
  background-color: #000;
  border: 3px solid #fff;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 5fr 1fr;
  align-items: center;
  color: #fff; }
  .hints.colored .the-hints {
    padding: 8px 0px 4px 8px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 0.5rem; }
    .hints.colored .the-hints .hints-color:not(.colorHintHasText) .hint-color:not(:last-child) {
      margin-right: 3px; }
    .hints.colored .the-hints .hints-color.colorHintHasText {
      margin-top: 4px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(22px, auto));
      grid-gap: 3px; }
    .hints.colored .the-hints .hint-color {
      display: inline-block;
      width: 22px;
      height: 22px;
      border-radius: 50%; }
      .hints.colored .the-hints .hint-color.yellow {
        background-color: #F0E442; }
      .hints.colored .the-hints .hint-color.green {
        background-color: #009E73; }
      .hints.colored .the-hints .hint-color.red {
        background-color: #D61B1B; }
      .hints.colored .the-hints .hint-color.grey {
        background-color: #999999; }
      .hints.colored .the-hints .hint-color.orange {
        background-color: #E69F00; }
      .hints.colored .the-hints .hint-color.blue {
        background-color: #0072B2; }
      .hints.colored .the-hints .hint-color.purple {
        background-color: #CC79A7; }
  .hints .the-count {
    font-size: 20px;
    font-weight: 700;
    text-align: right;
    padding-right: 15px; }

.play_timer {
  width: 50vw;
  max-width: 50%;
  margin: 0 auto;
  border: 2px solid #fff;
  padding: 4px 8px 4px 4px;
  min-height: 25px;
  color: #000;
  font-size: 25px; }
  .play_timer .bar {
    transition: width 0.4s ease-out;
    background-color: #fff;
    min-height: 23px; }

.game-play {
  width: 100vw; }
  .game-play .play {
    position: relative; }
  .game-play .actions {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 22px; }
    .game-play .actions .button, .game-play .actions button {
      border: 0px;
      background: transparent;
      text-transform: uppercase;
      transition: opacity 0.2s ease-out;
      line-height: 25px;
      transition: all 0.3s ease; }
      .game-play .actions .button:hover, .game-play .actions button:hover {
        -webkit-transform: scale(1.1);
        transform: scale(1.1); }
      .game-play .actions .button:hover, .game-play .actions button:hover {
        cursor: pointer; }
      .game-play .actions .button:first-child, .game-play .actions button:first-child {
        margin-right: 8px; }

.modal {
  position: absolute;
  z-index: 10000;
  pointer-events: auto;
  /* Allows clicks on the modal */
  top: 0;
  background-color: rgba(0, 0, 0, 0.5); }
  .modal.modal-message {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Nunito", sans-serif; }
    .modal.modal-message .content {
      position: relative;
      padding: 25px;
      border-radius: 5px;
      box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39);
      -webkit-box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39);
      -moz-box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39);
      width: 75%;
      max-width: 580px;
      max-height: 55vh;
      background-color: #fff;
      color: #000; }
      .modal.modal-message .content h1 {
        font-size: 1rem;
        font-weight: 700; }
        @media (min-width: 320px) {
          .modal.modal-message .content h1 {
            font-size: calc(1rem + 1.2 * (100vw - 320px) / 880); } }
        @media (min-width: 1200px) {
          .modal.modal-message .content h1 {
            font-size: 2.2rem; } }
      .modal.modal-message .content p, .modal.modal-message .content .body {
        font-size: 0.8rem; }
        @media (min-width: 320px) {
          .modal.modal-message .content p, .modal.modal-message .content .body {
            font-size: calc(0.8rem + 0.4 * (100vw - 320px) / 880); } }
        @media (min-width: 1200px) {
          .modal.modal-message .content p, .modal.modal-message .content .body {
            font-size: 1.2rem; } }
      .modal.modal-message .content .fadeContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
      .modal.modal-message .content .modal-action {
        width: 100%;
        display: flex;
        justify-content: center; }
        .modal.modal-message .content .modal-action .modal_action_button {
          font-family: 'Montserrat', sans-serif;
          border: 0px;
          background: transparent;
          text-transform: uppercase;
          transition: opacity 0.2s ease-out;
          line-height: 25px;
          -webkit-touch-callout: none;
          /* iOS Safari */
          -webkit-user-select: none;
          /* Safari */
          -khtml-user-select: none;
          /* Konqueror HTML */
          -moz-user-select: none;
          /* Old versions of Firefox */
          -ms-user-select: none;
          /* Internet Explorer/Edge */
          user-select: none;
          /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
          transition: all 0.3s ease;
          background-color: #363636;
          color: #ffffff;
          border: 6px solid #ffffff;
          padding: 12px 20px;
          border-radius: 15px;
          font-family: "Nunito", sans-serif;
          font-size: 1.1rem;
          font-weight: 600;
          letter-spacing: 0.04em;
          display: inline-block;
          margin: 50px auto 0; }
          .modal.modal-message .content .modal-action .modal_action_button:hover {
            -webkit-transform: scale(1.1);
            transform: scale(1.1); }
          .modal.modal-message .content .modal-action .modal_action_button:hover {
            cursor: pointer; }
          .modal.modal-message .content .modal-action .modal_action_button:focus {
            outline: none; }

#overlay {
  position: fixed;
  /* Fixed position to cover the whole screen */
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 9999;
  /* Overlay level */
  pointer-events: auto;
  /* Allows clicks on the overlay only */ }

button {
  font-size: 1.1rem; }
  button span.pixel {
    padding: 1px 20px 1px 20px;
    color: #363636;
    background-color: #ffffff;
    box-shadow: 0 -6px 0 -2px #ffffff, 0 6px 0 -2px #ffffff, 0 -18px 0 -8px #ffffff, 0 18px 0 -8px #ffffff, -2px 0 0 0 #363636, 2px 0 0 0 #363636, 0 -10px 0 -2px #363636, 0 10px 0 -2px #363636, 0 -20px 0 -8px #363636, 0 20px 0 -8px #363636, -4px 0 0 0 #363636, 4px 0 0 0 #363636, -6px 0 0 0 #363636, 6px 0 0 0 #363636, 0 -8px 0 0px #363636, 0 8px 0 0px #363636, 0 -16px 0 -4px #363636, 0 16px 0 -4px #363636, 0 -28px 0 -12px #363636, 0 28px 0 -12px #363636, -6px 0 0 0 #363636, 6px 0 0 0 #363636, 0 0 0 4px #363636, 0 -6px 0 2px #363636, 0 6px 0 2px #363636, 0 -14px 0 -2px #363636, 0 14px 0 -2px #363636, 0 -24px 0 -8px #363636, 0 24px 0 -8px #363636;
    filter: drop-shadow(6px 8px 3px rgba(0, 0, 0, 0.2));
    -webkit-filter: drop-shadow(6px 8px 3px rgba(0, 0, 0, 0.2));
    -moz-filter: drop-shadow(6px 8px 3px rgba(0, 0, 0, 0.2)); }
  button span.pixel-inverse {
    padding: 1px 20px 1px 20px;
    color: #ffffff;
    background-color: #363636;
    box-shadow: 0 -6px 0 -2px #363636, 0 6px 0 -2px #363636, 0 -18px 0 -8px #363636, 0 18px 0 -8px #363636, -2px 0 0 0 #ffffff, 2px 0 0 0 #ffffff, 0 -10px 0 -2px #ffffff, 0 10px 0 -2px #ffffff, 0 -20px 0 -8px #ffffff, 0 20px 0 -8px #ffffff, -4px 0 0 0 #ffffff, 4px 0 0 0 #ffffff, -6px 0 0 0 #ffffff, 6px 0 0 0 #ffffff, 0 -8px 0 0px #ffffff, 0 8px 0 0px #ffffff, 0 -16px 0 -4px #ffffff, 0 16px 0 -4px #ffffff, 0 -28px 0 -12px #ffffff, 0 28px 0 -12px #ffffff, -6px 0 0 0 #ffffff, 6px 0 0 0 #ffffff, 0 0 0 4px #ffffff, 0 -6px 0 2px #ffffff, 0 6px 0 2px #ffffff, 0 -14px 0 -2px #ffffff, 0 14px 0 -2px #ffffff, 0 -24px 0 -8px #ffffff, 0 24px 0 -8px #ffffff;
    filter: drop-shadow(6px 8px 3px rgba(0, 0, 0, 0.2));
    -webkit-filter: drop-shadow(6px 8px 3px rgba(0, 0, 0, 0.2));
    -moz-filter: drop-shadow(6px 8px 3px rgba(0, 0, 0, 0.2)); }
  button span.pixel-mission-mode {
    padding: 1px 20px 1px 20px;
    color: #ffffff;
    background-color: maroon;
    box-shadow: 0 -6px 0 -2px maroon, 0 6px 0 -2px maroon, 0 -18px 0 -8px maroon, 0 18px 0 -8px maroon, -2px 0 0 0 #ffffff, 2px 0 0 0 #ffffff, 0 -10px 0 -2px #ffffff, 0 10px 0 -2px #ffffff, 0 -20px 0 -8px #ffffff, 0 20px 0 -8px #ffffff, -4px 0 0 0 #ffffff, 4px 0 0 0 #ffffff, -6px 0 0 0 #ffffff, 6px 0 0 0 #ffffff, 0 -8px 0 0px #ffffff, 0 8px 0 0px #ffffff, 0 -16px 0 -4px #ffffff, 0 16px 0 -4px #ffffff, 0 -28px 0 -12px #ffffff, 0 28px 0 -12px #ffffff, -6px 0 0 0 #ffffff, 6px 0 0 0 #ffffff, 0 0 0 4px #ffffff, 0 -6px 0 2px #ffffff, 0 6px 0 2px #ffffff, 0 -14px 0 -2px #ffffff, 0 14px 0 -2px #ffffff, 0 -24px 0 -8px #ffffff, 0 24px 0 -8px #ffffff;
    filter: drop-shadow(6px 8px 3px rgba(0, 0, 0, 0.2));
    -webkit-filter: drop-shadow(6px 8px 3px rgba(0, 0, 0, 0.2));
    -moz-filter: drop-shadow(6px 8px 3px rgba(0, 0, 0, 0.2)); }

.tada {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0); }

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0); }
  40%, 60% {
    transform: translate3d(4px, 0, 0); } }

.selector {
  min-width: 135vw;
  margin: 0 auto; }
  .selector h4 {
    text-align: center;
    margin: 0 0 2rem 0; }
  .selector .vueperslides-backup {
    position: relative;
    top: -25vh; }
  .selector .vueperslides__arrows--outside .vueperslides__arrow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    padding: 1px 20px 1px 20px;
    color: #363636;
    background-color: #ffffff;
    box-shadow: 0 -6px 0 -2px #ffffff, 0 6px 0 -2px #ffffff, 0 -18px 0 -8px #ffffff, 0 18px 0 -8px #ffffff, -2px 0 0 0 #363636, 2px 0 0 0 #363636, 0 -10px 0 -2px #363636, 0 10px 0 -2px #363636, 0 -20px 0 -8px #363636, 0 20px 0 -8px #363636, -4px 0 0 0 #363636, 4px 0 0 0 #363636, -6px 0 0 0 #363636, 6px 0 0 0 #363636, 0 -8px 0 0px #363636, 0 8px 0 0px #363636, 0 -16px 0 -4px #363636, 0 16px 0 -4px #363636, 0 -28px 0 -12px #363636, 0 28px 0 -12px #363636, -6px 0 0 0 #363636, 6px 0 0 0 #363636, 0 0 0 4px #363636, 0 -6px 0 2px #363636, 0 6px 0 2px #363636, 0 -14px 0 -2px #363636, 0 14px 0 -2px #363636, 0 -24px 0 -8px #363636, 0 24px 0 -8px #363636;
    filter: drop-shadow(6px 8px 3px rgba(0, 0, 0, 0.2));
    -webkit-filter: drop-shadow(6px 8px 3px rgba(0, 0, 0, 0.2));
    -moz-filter: drop-shadow(6px 8px 3px rgba(0, 0, 0, 0.2));
    padding: 0px 5px 0px 5px;
    width: 60px;
    height: 30px;
    opacity: 1;
    z-index: 1000; }
    .selector .vueperslides__arrows--outside .vueperslides__arrow img {
      width: 40px;
      height: 40px; }
    .selector .vueperslides__arrows--outside .vueperslides__arrow :hover {
      -webkit-transform: scale(1.1);
      transform: scale(1.1); }
    .selector .vueperslides__arrows--outside .vueperslides__arrow.vueperslides__arrow--prev {
      left: 25vw; }
      .selector .vueperslides__arrows--outside .vueperslides__arrow.vueperslides__arrow--prev img {
        display: block;
        margin-top: -7px;
        margin-left: 3px;
        transform: rotate(180deg) scale(1.3); }
    .selector .vueperslides__arrows--outside .vueperslides__arrow.vueperslides__arrow--next {
      right: 25vw; }
      .selector .vueperslides__arrows--outside .vueperslides__arrow.vueperslides__arrow--next img {
        margin-top: -7px;
        transform: scale(1.3); }
  .selector .vueperslides__bullet {
    filter: drop-shadow(6px 8px 3px rgba(0, 0, 0, 0.2));
    -webkit-filter: drop-shadow(6px 8px 3px rgba(0, 0, 0, 0.2));
    -moz-filter: drop-shadow(6px 8px 3px rgba(0, 0, 0, 0.2));
    border-radius: 0;
    border: none;
    box-shadow: none;
    transition: 0.3s;
    width: 12px;
    height: 12px;
    padding: 10px;
    opacity: 0.8;
    background: url("https://ddzs4xvq8xxep.cloudfront.net/bullet.png") no-repeat;
    background-size: contain; }
    .selector .vueperslides__bullet .default {
      display: none; }
  .selector .vueperslides__bullet--active {
    background: url("https://ddzs4xvq8xxep.cloudfront.net/bullet-active.png") no-repeat; }

.vueperslide {
  background-size: cover;
  background-repeat: no-repeat;
  transition: .3s ease-in-out;
  border-radius: 10px;
  box-shadow: 5px 5px 10px -2px rgba(0, 0, 0, 0.5);
  padding: 0 !important;
  opacity: 0.7;
  top: 0;
  pointer-events: none;
  cursor: default;
  transform: scale(0.8); }
  .vueperslide span {
    display: none; }
  .vueperslide .start-game {
    width: 100%;
    height: 100%;
    display: block;
    min-width: 280px;
    min-height: 200px; }
    .vueperslide .start-game:hover {
      cursor: pointer !important; }
  .vueperslide.vueperslide--active {
    pointer-events: all;
    opacity: 1 !important;
    transform: scale(1);
    filter: blur(0); }
    .vueperslide.vueperslide--active:hover {
      transform: scale(1.02); }

.vueperslide, .vueperslide__image {
  background-position: top;
  padding: 0 !important; }

.vueperslides__parallax-wrapper, .vueperslides__track {
  overflow: visible !important; }

div.description {
  font-size: 1.2rem;
  line-height: 2.6rem;
  text-align: center;
  orphans: 3;
  font-weight: 500;
  padding: 20px;
  max-width: 70vw;
  position: absolute;
  z-index: 99999;
  margin: 0 auto;
  bottom: 5vh;
  vertical-align: top;
  padding: 1px 20px 1px 20px;
  color: #363636;
  background-color: #ffffff;
  box-shadow: 0 -6px 0 -2px #ffffff, 0 6px 0 -2px #ffffff, 0 -18px 0 -8px #ffffff, 0 18px 0 -8px #ffffff, -2px 0 0 0 #363636, 2px 0 0 0 #363636, 0 -10px 0 -2px #363636, 0 10px 0 -2px #363636, 0 -20px 0 -8px #363636, 0 20px 0 -8px #363636, -4px 0 0 0 #363636, 4px 0 0 0 #363636, -6px 0 0 0 #363636, 6px 0 0 0 #363636, 0 -8px 0 0px #363636, 0 8px 0 0px #363636, 0 -16px 0 -4px #363636, 0 16px 0 -4px #363636, 0 -28px 0 -12px #363636, 0 28px 0 -12px #363636, -6px 0 0 0 #363636, 6px 0 0 0 #363636, 0 0 0 4px #363636, 0 -6px 0 2px #363636, 0 6px 0 2px #363636, 0 -14px 0 -2px #363636, 0 14px 0 -2px #363636, 0 -24px 0 -8px #363636, 0 24px 0 -8px #363636;
  filter: drop-shadow(6px 8px 3px rgba(0, 0, 0, 0.2));
  -webkit-filter: drop-shadow(6px 8px 3px rgba(0, 0, 0, 0.2));
  -moz-filter: drop-shadow(6px 8px 3px rgba(0, 0, 0, 0.2)); }

.progress {
  margin: 0 auto;
  width: 50vw;
  height: 50px;
  border: 3px solid #000000;
  padding: 4px; }
  .progress .incrementor {
    width: 0%;
    height: 100%;
    background-color: #999999;
    transition: width 0.5s ease-out; }

.introduction .splash {
  text-align: center; }
  .introduction .splash img {
    border-radius: 10px;
    max-width: 62vw;
    width: 100%;
    -webkit-box-shadow: 5px 5px 6px -2px rgba(0, 0, 0, 0.67);
    box-shadow: 5px 5px 10px -2px rgba(0, 0, 0, 0.5); }

.first-encounter .gameplay-container {
  transform: translate(0px, 2000px);
  position: relative; }

.first-encounter .alien-ship {
  display: block;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  max-width: 30vw;
  height: auto;
  margin: 0 auto; }

.first-encounter .showAlienShip {
  opacity: 1; }

.first-encounter .board-timer-grid {
  max-width: 70%;
  margin: 0 auto; }
  .first-encounter .board-timer-grid .play_timer {
    flex: 0 0 35%;
    display: flex;
    justify-content: flex-start;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.6);
    padding: 10px;
    margin-top: 5vh; }
    .first-encounter .board-timer-grid .play_timer .bar {
      height: 22px;
      background-color: rgba(51, 51, 51, 0.9);
      border-radius: 11px;
      transition: width 0.1s linear;
      margin-left: 15px;
      margin-top: 4px; }
    .first-encounter .board-timer-grid .play_timer .time-left {
      text-align: center;
      font-weight: bold;
      margin-top: 5px; }
  @media (min-width: 1100px) {
    .first-encounter .board-timer-grid {
      max-width: calc(70% + 2 * (100vw - 1100px) / 100); } }
  @media (min-width: 1200px) {
    .first-encounter .board-timer-grid {
      max-width: 72%; } }
  .first-encounter .board-timer-grid .order-bonus {
    position: relative;
    z-index: 20;
    margin: 0 auto;
    top: -75vh;
    width: 30vw; }
    .first-encounter .board-timer-grid .order-bonus img {
      width: 100%;
      height: auto; }
    .first-encounter .board-timer-grid .order-bonus h2 {
      margin: 0; }

.first-encounter .grid {
  width: 100%;
  max-height: 80vh;
  margin: 0 auto;
  margin-top: -27px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 1.2rem;
  grid-row-gap: 1.2rem; }
  .first-encounter .grid .play-button {
    position: relative; }
    .first-encounter .grid .play-button:before {
      content: "";
      display: block;
      height: 0;
      width: 0;
      padding-bottom: calc(1/1 * 100%); }
    .first-encounter .grid .play-button span {
      display: none;
      position: absolute;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 5rem;
      z-index: 99999;
      width: 200%;
      left: -29%;
      top: 10px;
      padding: 5px;
      transition: all 0.2s ease;
      background-size: auto;
      height: 100%; }
    .first-encounter .grid .play-button img {
      position: absolute;
      top: 0;
      width: 107%;
      height: auto;
      display: inline-block;
      padding-bottom: 0px;
      overflow: hidden;
      border: 0px;
      background: transparent;
      outline: none;
      -webkit-transition: 0.2s ease-in-out;
      transition: 0.2s ease-in-out;
      display: inline-block;
      vertical-align: middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-property: transform;
      transition-property: transform; }
    @media (min-width: 1100px) {
      .first-encounter .grid .play-button {
        width: 85%; } }
  .first-encounter .grid .hint-mode {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0); }
    .first-encounter .grid .hint-mode:active {
      -webkit-animation-name: hvr-push;
      animation-name: hvr-push;
      -webkit-animation-duration: 0.3s;
      animation-duration: 0.3s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1; }
  .first-encounter .grid .play-button:not(.play-disabled):not(.correct):not(.incorrect):hover {
    cursor: pointer; }
  .first-encounter .grid .play-button:not(.play-disabled):not(.correct):not(.incorrect) img:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    cursor: pointer; }
  .first-encounter .grid .play-button:not(.play-disabled):not(.correct):not(.incorrect):not(.play-disabled):not(.incorrect):active {
    -webkit-animation-name: hvr-push;
    animation-name: hvr-push;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1; }
  .first-encounter .grid .play-button:not(.play-disabled):not(.correct):not(.incorrect).incorrect:not(.play-disabled) {
    -webkit-animation-name: hvr-icon-buzz-out;
    animation-name: hvr-icon-buzz-out;
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1; }
  .first-encounter .grid .play-button:not(.play-disabled):not(.correct):not(.incorrect).incorrect, .first-encounter .grid .play-button:not(.play-disabled):not(.correct):not(.incorrect) .play-disabled {
    cursor: default; }

.master-thief {
  position: static; }
  .master-thief .gameplay-container {
    transform: translate(0px, 2000px); }
  .master-thief .safe, .master-thief .grid {
    max-width: 80%;
    aspect-ratio: 3 / 2; }
    @media (min-width: 1100px) {
      .master-thief .safe, .master-thief .grid {
        max-width: calc(80% + -21 * (100vw - 1100px) / 100); } }
    @media (min-width: 1200px) {
      .master-thief .safe, .master-thief .grid {
        max-width: 59%; } }
  .master-thief .safe {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    aspect-ratio: 3 / 2;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    box-shadow: 1px 1px 0px rgba(0,0,0,0.1) ,2px 2px 0px rgba(0,0,0,0.1) ;
    margin-top: 10px;
    padding: 1.8em 1.8em 0;
    z-index: 5; }
    .master-thief .safe .gems {
      width: 80%;
      margin: 0 auto;
      position: absolute;
      bottom: 5vh; }
      .master-thief .safe .gems span {
        display: block; }
        .master-thief .safe .gems span .gem {
          display: inline-block;
          width: 33%;
          height: auto;
          transition: all 0.2s ease; }
        .master-thief .safe .gems span .gemAnimateOut {
          transform: translateX(-10vw), translateY(-100%); }
  .master-thief .grid {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    transform: translate(-3000px, 0px);
    z-index: 10;
    display: grid;
    margin-top: 10px;
    grid-template-columns: repeat(7, 12%);
    grid-gap: 1em;
    padding: 1em; }
    @media (min-width: 1100px) {
      .master-thief .grid {
        grid-gap: calc(1em + -0.2 * (100vw - 1100px) / 100); } }
    @media (min-width: 1200px) {
      .master-thief .grid {
        grid-gap: 0.8em; } }
    .master-thief .grid .play-button {
      min-height: 0;
      position: relative; }
      .master-thief .grid .play-button.show.hint-mode img {
        will-change: transform, opacity;
        animation: pulse var(--display-interval) infinite; }
        .master-thief .grid .play-button.show.hint-mode img:hover {
          cursor: pointer; }

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1; }
  50% {
    transform: scale(1.05);
    opacity: 0.8; } }
      .master-thief .grid .play-button.correct.active {
        background-color: pink; }
      .master-thief .grid .play-button:before {
        content: "";
        display: block;
        height: 0;
        width: 0;
        padding-bottom: calc(1/1 * 100%); }
      .master-thief .grid .play-button span {
        display: none;
        position: absolute;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 5rem;
        z-index: 99999;
        width: 200%;
        left: -29%;
        top: 10px;
        padding: 5px;
        transition: all 0.2s ease;
        background-size: auto;
        height: 100%; }
      .master-thief .grid .play-button img {
        position: absolute;
        top: 0;
        width: 100%;
        height: auto;
        display: inline-block;
        padding-bottom: 0px;
        overflow: hidden;
        border: 0px;
        background: transparent;
        outline: none; }
      .master-thief .grid .play-button.correct img, .master-thief .grid .play-button.incorrect img {
        width: 100%; }
    .master-thief .grid .hint-mode {
      display: inline-block;
      vertical-align: middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0); }
      .master-thief .grid .hint-mode:active {
        -webkit-animation-name: hvr-push;
        animation-name: hvr-push;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1; }
    .master-thief .grid .play-button:not(.play-disabled).show:hover {
      cursor: pointer; }
    .master-thief .grid .play-button:not(.play-disabled) img:hover {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
      cursor: pointer; }
    .master-thief .grid .play-button:not(.play-disabled) img:not(.play-disabled):not(.incorrect):active {
      -webkit-animation-name: hvr-push;
      animation-name: hvr-push;
      -webkit-animation-duration: 0.3s;
      animation-duration: 0.3s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1; }
    .master-thief .grid .play-button:not(.play-disabled) img.incorrect:not(.play-disabled) {
      -webkit-animation-name: hvr-icon-buzz-out;
      animation-name: hvr-icon-buzz-out;
      -webkit-animation-duration: 0.75s;
      animation-duration: 0.75s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1; }
    .master-thief .grid .play-button:not(.play-disabled) img.incorrect, .master-thief .grid .play-button:not(.play-disabled) img .play-disabled {
      cursor: default; }
  .master-thief .play_timer {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 83vh;
    z-index: 6666; }

.scout-trainer .gameplay-container {
  transform: translate(0px, -2000px); }
  .scout-trainer .gameplay-container.play-mode .play-button .army {
    transition: all 0.2s ease; }
    .scout-trainer .gameplay-container.play-mode .play-button .army:not(.noHoverEffect):hover {
      box-shadow: inset 0px 0px 0px 8px #000000;
      box-sizing: border-box;
      background: repeating-linear-gradient(45deg, #000000, rgba(0, 0, 0, 0) 5px);
      cursor: pointer; }
  .scout-trainer .gameplay-container .order-bonus {
    position: absolute;
    z-index: 20;
    margin: 0 auto;
    top: -15vh;
    transform: rotate(-10deg);
    right: 10vw;
    width: 15vw; }
    .scout-trainer .gameplay-container .order-bonus img {
      width: 100%;
      height: auto; }
    .scout-trainer .gameplay-container .order-bonus h2 {
      margin: 0; }

.scout-trainer .grid {
  max-height: 80vh;
  margin: 10px auto 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 0; }
  .scout-trainer .grid .play-button {
    position: relative;
    background: url("https://ddzs4xvq8xxep.cloudfront.net/games/ScoutTrainer/images/background.webp") no-repeat;
    aspect-ratio: 1 / 1;
    background-size: cover; }
    @media (min-width: 768px) {
      .scout-trainer .grid .play-button {
        background: url("https://ddzs4xvq8xxep.cloudfront.net/games/ScoutTrainer/images/small/background.webp") no-repeat; } }
    @media (min-width: 1001px) {
      .scout-trainer .grid .play-button {
        background: url("https://ddzs4xvq8xxep.cloudfront.net/games/ScoutTrainer/images/medium/background.webp") no-repeat; } }
    @media (min-width: 1801px) {
      .scout-trainer .grid .play-button {
        background: url("https://ddzs4xvq8xxep.cloudfront.net/games/ScoutTrainer/images/large/background.webp") no-repeat; } }
    .scout-trainer .grid .play-button::before {
      content: '';
      width: 0;
      padding-bottom: 100%;
      grid-row: 1 / 1;
      grid-column: 1 / 1; }
    .scout-trainer .grid .play-button > *:first-child {
      grid-row: 1 / 1;
      grid-column: 1 / 1; }
    .scout-trainer .grid .play-button .army {
      transition: all 0.2s ease;
      height: 100%;
      position: relative;
      z-index: 5;
      display: grid;
      grid-template-columns: repeat(3, minmax(1px, 1fr));
      grid-auto-rows: 1fr;
      padding: 18px;
      grid-column-gap: 15px;
      grid-row-gap: 5px; }
      .scout-trainer .grid .play-button .army .orc {
        opacity: 0;
        transition: top 0.8s ease-out 0.5s, opacity 1s ease 0.3s, transform ease 0.5s;
        position: relative; }
        .scout-trainer .grid .play-button .army .orc.shown {
          top: 0;
          opacity: 1; }
        .scout-trainer .grid .play-button .army .orc.faded {
          top: 0;
          opacity: 0; }
        .scout-trainer .grid .play-button .army .orc.dead {
          top: 0;
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
    .scout-trainer .grid .play-button.incorrect .army, .scout-trainer .grid .play-button.incorrect .army:hover {
      box-shadow: inset 0px 0px 0px 8px #b01a1a;
      box-sizing: border-box;
      background: repeating-linear-gradient(45deg, #b01a1a, rgba(0, 0, 0, 0) 5px); }
    .scout-trainer .grid .play-button .orc {
      opacity: 0;
      transition: top 0.8s ease-out 0.5s, opacity 1s ease 0.3s, transform ease 1s; }
    .scout-trainer .grid .play-button:not(.hint-mode) .orc {
      opacity: 0; }
    .scout-trainer .grid .play-button span {
      display: none; }
    .scout-trainer .grid .play-button.tile1:before {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("https://ddzs4xvq8xxep.cloudfront.net/games/ScoutTrainer/images/tile-overlay1.png") no-repeat;
      filter: brightness(1.2) contrast(1.5); }
      @media (min-width: 768px) {
        .scout-trainer .grid .play-button.tile1:before {
          background: url("https://ddzs4xvq8xxep.cloudfront.net/games/ScoutTrainer/images/small/tile-overlay1.png") no-repeat; } }
      @media (min-width: 1001px) {
        .scout-trainer .grid .play-button.tile1:before {
          background: url("https://ddzs4xvq8xxep.cloudfront.net/games/ScoutTrainer/images/medium/tile-overlay1.png") no-repeat; } }
      @media (min-width: 1801px) {
        .scout-trainer .grid .play-button.tile1:before {
          background: url("https://ddzs4xvq8xxep.cloudfront.net/games/ScoutTrainer/images/large/tile-overlay1.png") no-repeat; } }
    .scout-trainer .grid .play-button.tile2:before {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("https://ddzs4xvq8xxep.cloudfront.net/games/ScoutTrainer/images/tile-overlay2.png") no-repeat;
      filter: brightness(0.9); }
      @media (min-width: 768px) {
        .scout-trainer .grid .play-button.tile2:before {
          background: url("https://ddzs4xvq8xxep.cloudfront.net/games/ScoutTrainer/images/small/tile-overlay2.png") no-repeat; } }
      @media (min-width: 1001px) {
        .scout-trainer .grid .play-button.tile2:before {
          background: url("https://ddzs4xvq8xxep.cloudfront.net/games/ScoutTrainer/images/medium/tile-overlay2.png") no-repeat; } }
      @media (min-width: 1801px) {
        .scout-trainer .grid .play-button.tile2:before {
          background: url("https://ddzs4xvq8xxep.cloudfront.net/games/ScoutTrainer/images/large/tile-overlay2.png") no-repeat; } }
    .scout-trainer .grid .play-button.tile3:before {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("https://ddzs4xvq8xxep.cloudfront.net/games/ScoutTrainer/images/tile-overlay3.png") no-repeat;
      filter: brightness(0); }
      @media (min-width: 768px) {
        .scout-trainer .grid .play-button.tile3:before {
          background: url("https://ddzs4xvq8xxep.cloudfront.net/games/ScoutTrainer/images/small/tile-overlay3.png") no-repeat; } }
      @media (min-width: 1001px) {
        .scout-trainer .grid .play-button.tile3:before {
          background: url("https://ddzs4xvq8xxep.cloudfront.net/games/ScoutTrainer/images/medium/tile-overlay3.png") no-repeat; } }
      @media (min-width: 1801px) {
        .scout-trainer .grid .play-button.tile3:before {
          background: url("https://ddzs4xvq8xxep.cloudfront.net/games/ScoutTrainer/images/large/tile-overlay3.png") no-repeat; } }
    .scout-trainer .grid .play-button img {
      max-width: 100%;
      height: auto;
      display: inline-block;
      padding-bottom: 0px;
      overflow: hidden;
      border: 0px;
      background: transparent;
      outline: none;
      -webkit-transition: 0.2s ease-in-out;
      transition: 0.2s ease-in-out;
      display: inline-block;
      vertical-align: middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-property: transform;
      transition-property: transform; }
  .scout-trainer .grid .hint-mode {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    aspect-ratio: 1 / 1;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0); }
    .scout-trainer .grid .hint-mode:active {
      -webkit-animation-name: hvr-push;
      animation-name: hvr-push;
      -webkit-animation-duration: 0.3s;
      animation-duration: 0.3s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1; }
  .scout-trainer .grid .play-button:not(.play-disabled):not(.correct):not(.incorrect):hover {
    cursor: pointer; }
  .scout-trainer .grid .play-button:not(.play-disabled):not(.correct):not(.incorrect) img:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    cursor: pointer; }
  .scout-trainer .grid .play-button:not(.play-disabled):not(.correct):not(.incorrect) img:not(.play-disabled):not(.incorrect):active {
    -webkit-animation-name: hvr-push;
    animation-name: hvr-push;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1; }
  .scout-trainer .grid .play-button:not(.play-disabled):not(.correct):not(.incorrect) img.incorrect:not(.play-disabled) {
    -webkit-animation-name: hvr-icon-buzz-out;
    animation-name: hvr-icon-buzz-out;
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1; }
  .scout-trainer .grid .play-button:not(.play-disabled):not(.correct):not(.incorrect) img.incorrect, .scout-trainer .grid .play-button:not(.play-disabled):not(.correct):not(.incorrect) img .play-disabled {
    cursor: default; }

.scout-trainer .feedback-and-timer {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box; }

.scout-trainer .gameplay-feedback {
  flex: 1;
  position: relative;
  z-index: 50;
  max-height: 100px;
  overflow: hidden;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px 0 0 5px;
  box-shadow: -5px 5px 8px 0px rgba(0, 0, 0, 0.6); }
  .scout-trainer .gameplay-feedback h4.scout-instructions {
    padding: 15px;
    margin: 0; }

.scout-trainer .play_timer {
  flex: 0 0 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.6);
  padding: 10px; }
  .scout-trainer .play_timer .bar {
    height: 22px;
    background-color: rgba(51, 51, 51, 0.9);
    border-radius: 11px;
    transition: width 0.1s linear; }
  .scout-trainer .play_timer .time-left {
    text-align: center;
    font-weight: bold;
    margin-top: 5px; }

@media (max-width: 768px) {
  .scout-trainer .feedback-and-timer {
    flex-direction: column;
    align-items: stretch; }
  .scout-trainer .gameplay-feedback,
  .scout-trainer .play_timer {
    width: 100%;
    max-width: 100%;
    border-radius: 5px;
    margin-bottom: 10px; } }

.scout-trainer .play_timer {
  display: grid;
  grid-template-columns: 1fr 10fr;
  align-items: center; }

@keyframes slideIn {
  from {
    transform: translateX(-100%); }
  to {
    transform: translateX(0); } }

@keyframes slideOut {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(100%); } }

.scout-trainer .slide-in {
  animation: slideIn 0.5s forwards; }

.scout-trainer .slide-out {
  animation: slideOut 0.5s forwards; }

.scout-trainer .disabled, .scout-trainer .disabled *, .scout-trainer [disabled], .scout-trainer [disabled] * {
  cursor: default !important; }

.short-circuit .gameplay-container {
  transform: translate(0px, 2000px);
  background-position-x: 50% !important; }

.short-circuit .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
  top: 0; }
  .short-circuit .modal-container .modal.modal-short-circuit {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShortCircuit/images/modal-background.webp");
    width: 60%;
    height: 70%;
    margin: auto;
    top: auto;
    box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39);
    -webkit-box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39);
    -moz-box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39); }
    @media (min-width: 768px) {
      .short-circuit .modal-container .modal.modal-short-circuit {
        background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShortCircuit/images/small/modal-background.webp"); } }
    @media (min-width: 1001px) {
      .short-circuit .modal-container .modal.modal-short-circuit {
        background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShortCircuit/images/medium/modal-background.webp"); } }
    @media (min-width: 1801px) {
      .short-circuit .modal-container .modal.modal-short-circuit {
        background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShortCircuit/images/large/modal-background.webp"); } }
    .short-circuit .modal-container .modal.modal-short-circuit .modal-body {
      width: 90%; }
      .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers {
        position: relative;
        margin: 0 auto;
        overflow: visible;
        border-radius: 15px;
        height: 210px;
        box-shadow: inset 6px 6px 12px #444, inset -6px -6px 36px #666;
        color: #444;
        padding: 35px 25px 35px 15px;
        background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShortCircuit/images/modal-input.webp"); }
        .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers h1 {
          font-size: 4.2rem;
          line-height: 100%;
          padding-bottom: 10px; }
          .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers h1.correct {
            color: green; }
          .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers h1.incorrect {
            color: red; }
          .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers h1 div.sequence-numbers {
            font-family: 'Nunito', serif;
            font-style: normal;
            display: inline-block;
            line-height: 100%;
            font-weight: 900; }
          .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers h1 span.comma-separator {
            padding: 10px; }
        .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers .sequence-number-grid {
          display: flex;
          grid-gap: 0; }
          .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers .sequence-number-grid .sequence-number {
            font-family: 'Nunito', serif;
            font-style: normal;
            display: inline-block;
            line-height: 100%;
            font-weight: 900; }
        .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers .sequence-numbers {
          position: relative;
          margin: 0px 5px 0px 5px;
          padding: 0 0.6rem 0 0.6rem;
          line-height: 0.5; }
          .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers .sequence-numbers .answer-text {
            text-align: center;
            font-size: 4rem;
            font-family: 'Nunito', serif;
            font-style: normal;
            display: block;
            margin-top: 40%;
            margin-bottom: 0;
            line-height: 100%;
            font-weight: 900; }
          .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers .sequence-numbers .background {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            transition: 300ms background-color cubic-bezier(0.55, 0, 1, 0.45);
            background-color: rgba(68, 68, 68, 0.2);
            border-radius: 10px; }
          .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers .sequence-numbers > :not(.background) {
            position: relative;
            z-index: 1; }
          .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers .sequence-numbers.unchecked .background {
            background-color: rgba(68, 68, 68, 0.2); }
          .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers .sequence-numbers.correct .background {
            background-color: #1f19; }
          .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers .sequence-numbers.incorrect .background {
            background-color: #f119; }
          .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers .sequence-numbers.incorrect .answer-text {
            color: #fff; }
        @media (min-width: 768px) {
          .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers {
            background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShortCircuit/images/small/modal-input.webp"); } }
        @media (min-width: 1001px) {
          .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers {
            background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShortCircuit/images/medium/modal-input.webp"); } }
        @media (min-width: 1801px) {
          .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers {
            background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShortCircuit/images/large/modal-input.webp"); } }
        .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers div.answer-instructions {
          width: 100%;
          text-align: center;
          margin-top: 10px;
          position: absolute;
          left: 0;
          bottom: 0;
          padding-top: 10px;
          background: rgba(255, 255, 255, 0.5);
          border-radius: 0 0 15px 15px; }
          .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers div.answer-instructions p {
            font-size: 16px;
            font-weight: 900;
            color: #000;
            text-align: center; }
            .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers div.answer-instructions p.correct {
              color: green; }
            .short-circuit .modal-container .modal.modal-short-circuit .modal-body .numbers div.answer-instructions p.incorrect {
              color: red; }
      .short-circuit .modal-container .modal.modal-short-circuit .modal-body .number-buttons {
        margin-top: 25px;
        margin-bottom: 25px;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-gap: 5px; }
        .short-circuit .modal-container .modal.modal-short-circuit .modal-body .number-buttons button {
          font-size: 24px;
          height: 40px;
          border-radius: 5px;
          -webkit-transition: all 0.4s ease-out;
          -moz-transition: all 0.4s ease-out;
          -ms-transition: all 0.4s ease-out;
          -o-transition: all 0.4s ease-out;
          transition: all 0.4s ease-out;
          border: 0;
          font-family: "Nunito", sans-serif;
          font-weight: 900; }
          .short-circuit .modal-container .modal.modal-short-circuit .modal-body .number-buttons button:hover {
            cursor: pointer;
            background-color: #d3d3d3;
            box-shadow: inset 0px 0px 0px 4px #f3f3f3;
            box-sizing: border-box; }
      .short-circuit .modal-container .modal.modal-short-circuit .modal-body .short-circuit-modal-button {
        margin-right: 5px;
        font-size: 18px;
        border-radius: 5px;
        border: 3px solid #c3c3c3;
        padding: 10px 15px;
        -webkit-transition: all 0.4s ease-out;
        -moz-transition: all 0.4s ease-out;
        -ms-transition: all 0.4s ease-out;
        -o-transition: all 0.4s ease-out;
        transition: all 0.4s ease-out;
        font-family: "NunitoBlack", sans-serif;
        font-weight: 600; }
        .short-circuit .modal-container .modal.modal-short-circuit .modal-body .short-circuit-modal-button:hover {
          cursor: pointer;
          background-color: #d3d3d3;
          box-shadow: inset 0px 0px 0px 4px #f3f3f3;
          box-sizing: border-box; }

.short-circuit .binding {
  background-color: #fff;
  position: absolute;
  z-index: 20000;
  top: -80px;
  right: 10px;
  color: #000;
  font-weight: 700;
  font-size: 20px;
  list-style: none; }

.short-circuit .grid {
  max-width: 80%;
  margin: 0 auto;
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(6, minmax(30px, 100px));
  grid-gap: 1.3rem;
  padding: 4.8em;
  justify-content: center;
  align-content: center; }
  @media (min-width: 1100px) {
    .short-circuit .grid {
      max-width: calc(80% + -14 * (100vw - 1100px) / 100); } }
  @media (min-width: 1200px) {
    .short-circuit .grid {
      max-width: 66%; } }
  @media (min-width: 1100px) {
    .short-circuit .grid {
      grid-template-columns: calc(repeat(6, minmax(30px, 100px)) + repeat(6, minmax(30px, 120px))-repeat(6, minmax(30px, 100px)) * (100vw - 1100px) / 100); } }
  @media (min-width: 1200px) {
    .short-circuit .grid {
      grid-template-columns: repeat(6, minmax(30px, 120px)); } }
  .short-circuit .grid .play-button {
    min-height: 0;
    position: relative; }
    .short-circuit .grid .play-button.correct.active {
      background-color: pink; }
    .short-circuit .grid .play-button:before {
      content: "";
      display: block;
      height: 0;
      width: 0;
      padding-bottom: calc(1 / 1 * 100%); }
    .short-circuit .grid .play-button span {
      display: none;
      position: absolute;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 5rem;
      z-index: 99999;
      width: 200%;
      left: -29%;
      top: 10px;
      padding: 5px;
      transition: all 0.2s ease;
      background-size: auto;
      height: 100%; }
    .short-circuit .grid .play-button img {
      position: absolute;
      top: 0;
      width: 100%;
      height: auto;
      display: inline-block;
      padding-bottom: 0px;
      overflow: hidden;
      border: 0px;
      background: transparent;
      outline: none;
      -webkit-transition: 0.2s ease-in-out;
      transition: 0.2s ease-in-out;
      display: inline-block;
      vertical-align: middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-property: transform;
      transition-property: transform; }
    .short-circuit .grid .play-button.correct img, .short-circuit .grid .play-button.incorrect img {
      width: 100%; }
    .short-circuit .grid .play-button.correct:after {
      content: '';
      position: absolute;
      left: 25%;
      top: 23%;
      z-index: 10;
      width: 50%;
      height: 50%;
      background: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShortCircuit/images/medium/check.svg") no-repeat center center;
      background-size: contain;
      opacity: 1;
      transition: opacity 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55); }
    .short-circuit .grid .play-button.incorrect:after {
      content: '';
      position: absolute;
      left: 25%;
      top: 23%;
      z-index: 10;
      width: 50%;
      height: 50%;
      background: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShortCircuit/images/images/incorrect-border.svg") no-repeat center center;
      background-size: contain;
      opacity: 1;
      transition: opacity 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .short-circuit .grid .hint-mode {
    transition: all 0.3s; }
    .short-circuit .grid .hint-mode:not(.correct) {
      -webkit-animation: flash linear 1s infinite;
      animation: flash linear 1s infinite; }
    .short-circuit .grid .hint-mode:active {
      -webkit-animation-name: hvr-push;
      animation-name: hvr-push;
      -webkit-animation-duration: 0.3s;
      animation-duration: 0.3s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1; }
    .short-circuit .grid .hint-mode:hover {
      opacity: 1;
      animation: none; }
  .short-circuit .grid .play-button.hint-mode:not(.correct):not(.incorrect):hover {
    cursor: pointer; }
  .short-circuit .grid .play-button.hint-mode:not(.correct):not(.incorrect) img:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    cursor: pointer; }
  .short-circuit .grid .play-button.hint-mode:not(.correct):not(.incorrect) img:not(.play-disabled):not(.incorrect):active {
    -webkit-animation-name: hvr-push;
    animation-name: hvr-push;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1; }
  .short-circuit .grid .play-button.hint-mode:not(.correct):not(.incorrect) img.incorrect:not(.play-disabled) {
    -webkit-animation-name: hvr-icon-buzz-out;
    animation-name: hvr-icon-buzz-out;
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1; }
  .short-circuit .grid .play-button.hint-mode:not(.correct):not(.incorrect) img.incorrect, .short-circuit .grid .play-button.hint-mode:not(.correct):not(.incorrect) img .play-disabled {
    cursor: default; }

.short-circuit .play_timer {
  margin-top: 25px;
  min-height: 34px;
  height: 34px;
  position: absolute;
  left: 0;
  right: 0; }

.answerModal-enter-active, .answerModal-leave-active {
  transition: transform 1s ease-out; }

.answerModal-leave {
  transform: translateY(-1200px); }

.answerModal-enter, .answerModal-leave-to {
  transform: translateY(1200px); }

.list-enter-active {
  opacity: 0;
  transition: opacity 10s; }

.list-enter,
.list-leave-to {
  opacity: 0; }

#the-decoder {
  height: 100%; }
  #the-decoder .crack-code .code-header {
    max-height: 82px; }
  #the-decoder .crack-code .code-wrapper {
    margin-top: 30px;
    height: 56px;
    font-size: 50px;
    line-height: 1;
    color: white;
    background-color: black; }
    #the-decoder .crack-code .code-wrapper > span {
      position: relative; }
    #the-decoder .crack-code .code-wrapper .label {
      position: absolute;
      bottom: 100%;
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      color: black; }
    #the-decoder .crack-code .code-wrapper > span:nth-of-type(2) .label {
      padding-left: 10px; }
    #the-decoder .crack-code .code-wrapper .characters {
      position: relative;
      display: inline-block;
      margin: 3px 3px;
      padding: 0 5px;
      line-height: 1; }
      #the-decoder .crack-code .code-wrapper .characters .background {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        perspective: 800px;
        backface-visibility: hidden;
        transform: rotate3D(0, 1, 0, 90deg);
        transition: 300ms background-color cubic-bezier(0.55, 0, 1, 0.45), 500ms transform cubic-bezier(0.32, 0, 0.67, 0); }
      #the-decoder .crack-code .code-wrapper .characters > :not(.background) {
        position: relative;
        z-index: 1; }
      #the-decoder .crack-code .code-wrapper .characters.correct .background {
        transform: rotate3D(0, 1, 0, 0deg);
        background-color: #1f19; }
      #the-decoder .crack-code .code-wrapper .characters.incorrect .background {
        transform: rotate3D(0, 1, 0, 0deg);
        background-color: #f119; }
    #the-decoder .crack-code .code-wrapper input {
      width: 0;
      opacity: 0; }
  #the-decoder .crack-code .button-wrapper button:nth-child(2) {
    margin-left: auto; }
  #the-decoder .crack-code .button-wrapper button:nth-child(3) {
    margin-left: 10px; }
  #the-decoder .find-code {
    background-size: cover;
    cursor: none; }
    #the-decoder .find-code > .symbol {
      position: relative;
      display: inline-flex;
      width: 20%;
      min-width: 50px; }
      #the-decoder .find-code > .symbol img {
        margin: auto;
        width: 100%;
        height: auto;
        object-fit: scale-down; }
        #the-decoder .find-code > .symbol img.animate {
          animation: pulse 1000ms infinite; }
    #the-decoder .find-code .cursor {
      position: absolute;
      align-self: start;
      justify-self: start;
      width: auto !important;
      transform: translate(-50%, calc(-50% + 15px));
      pointer-events: none; }
  @supports (display: grid) {
    #the-decoder .find-code {
      display: grid;
      grid-template-columns: repeat(5, minmax(auto, 15vw));
      grid-template-rows: repeat(3, minmax(auto, 15vw));
      justify-items: center;
      align-items: center;
      justify-content: center;
      align-content: center; }
      #the-decoder .find-code .symbol {
        padding: 5px;
        width: 100%; }
    @media (orientation: portrait) {
      #the-decoder .find-code {
        grid-template-columns: repeat(3, minmax(auto, 15vh));
        grid-template-rows: repeat(5, minmax(auto, 15vh)); } } }
  #the-decoder .keyboards {
    display: flex;
    gap: 5px;
    margin: 15px 0; }
    #the-decoder .keyboards .letters,
    #the-decoder .keyboards .numbers {
      display: flex;
      flex-flow: wrap;
      gap: 2px;
      justify-content: center; }
    #the-decoder .keyboards .key {
      font-size: 24px;
      height: 40px;
      border-radius: 5px;
      -webkit-transition: all 0.4s ease-out;
      -moz-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
      -o-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out;
      border: 0;
      font-family: "Nunito", sans-serif;
      font-weight: 900; }
      #the-decoder .keyboards .key:hover {
        cursor: pointer;
        background-color: #d3d3d3;
        box-shadow: inset 0px 0px 0px 4px #f3f3f3;
        box-sizing: border-box; }
    #the-decoder .keyboards .letters .key {
      width: calc(10% - 3px); }
    #the-decoder .keyboards .numbers .key {
      flex-grow: 1; }
    #the-decoder .keyboards .break {
      visibility: hidden;
      flex-basis: 100%; }
    @media (max-width: 449px) {
      #the-decoder .keyboards {
        flex-direction: column-reverse; }
        #the-decoder .keyboards .numbers .break {
          display: none; }
        #the-decoder .keyboards .numbers :nth-child(1) {
          order: 7; }
        #the-decoder .keyboards .numbers :nth-child(2) {
          order: 8; }
        #the-decoder .keyboards .numbers :nth-child(3) {
          order: 9; }
        #the-decoder .keyboards .numbers :nth-child(5) {
          order: 4; }
        #the-decoder .keyboards .numbers :nth-child(6) {
          order: 5; }
        #the-decoder .keyboards .numbers :nth-child(7) {
          order: 6; }
        #the-decoder .keyboards .numbers :nth-child(9) {
          order: 1; }
        #the-decoder .keyboards .numbers :nth-child(10) {
          order: 2; }
        #the-decoder .keyboards .numbers :nth-child(11) {
          order: 3; }
        #the-decoder .keyboards .numbers :nth-child(13) {
          order: 10; } }
  #the-decoder .note {
    margin: 20px auto 0px auto;
    padding: 25px;
    border-radius: 5px;
    width: 80%;
    max-width: 1200px;
    color: black;
    background-color: #f4e396;
    box-shadow: 2px 3px 22px -4px rgba(0, 0, 0, 0.54);
    -webkit-box-shadow: 2px 3px 22px -4px rgba(0, 0, 0, 0.54);
    -moz-box-shadow: 2px 3px 22px -4px rgba(0, 0, 0, 0.54); }
    #the-decoder .note .stars {
      margin-bottom: 30px;
      padding-bottom: 10px;
      border-bottom: 2px dotted black;
      font-size: 40px;
      text-align: center; }
    #the-decoder .note h1 {
      margin: 0;
      font-size: 50px;
      font-weight: 700;
      line-height: 1; }
    #the-decoder .note h2 {
      margin: 10px 0 10px;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.1; }
    #the-decoder .note .button-wrapper {
      display: flex;
      margin: 20px 0; }
      #the-decoder .note .button-wrapper > :first-child {
        margin-right: auto; }
      #the-decoder .note .button-wrapper > :last-child {
        margin-left: auto; }
      #the-decoder .note .button-wrapper button {
        font-size: 18px;
        height: 40px;
        border-radius: 5px;
        -webkit-transition: all 0.4s ease-out;
        -moz-transition: all 0.4s ease-out;
        -ms-transition: all 0.4s ease-out;
        -o-transition: all 0.4s ease-out;
        transition: all 0.4s ease-out;
        border: 0;
        font-family: "Nunito", sans-serif;
        font-weight: 900; }
        #the-decoder .note .button-wrapper button:hover {
          cursor: pointer;
          background-color: #d3d3d3;
          box-shadow: inset 0px 0px 0px 4px #f3f3f3;
          box-sizing: border-box; }
  #the-decoder .code-character {
    position: absolute;
    pointer-events: none; }

.phase-transition-enter-active,
.phase-transition-leave-active {
  transition: color 1.5s; }

.phase-transition-enter-active .symbol,
.phase-transition-leave-active .symbol {
  transition: opacity 500ms var(--delay), transform 500ms var(--delay) cubic-bezier(0.11, 0, 0.5, 0), filter 500ms var(--delay); }

.phase-transition-enter .symbol,
.phase-transition-leave-to .symbol {
  opacity: 0;
  transform: scale(0.2);
  filter: blur(10px); }

.phase-transition-enter-active.note,
.phase-transition-leave-active.note {
  transition: 650ms transform cubic-bezier(0.65, 0, 0.35, 1), 700ms box-shadow ease-out;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1); }

.phase-transition-enter.note,
.phase-transition-leave-to.note {
  transform: translateY(100%);
  box-shadow: 0px -20px 10px rgba(0, 0, 0, 0.1); }

.fade-enter-active,
.fade-leave-active {
  transition: 300ms opacity ease-out, 400ms filter ease-in; }

.fade-enter, .fade-leave-to {
  opacity: 0;
  filter: blur(2px); }

@keyframes pulse {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(0.8); }
  0% {
    transform: scale(1); } }

.black-text {
  color: black !important; }

.white-text {
  color: white !important; }

.wordwheel__white_background {
  background: white;
  text-transform: uppercase; }

.wordwheel__incorrect_background {
  background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/background-incorrect.webp") no-repeat; }
  @media (min-width: 768px) {
    .wordwheel__incorrect_background {
      background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/small/background-incorrect.webp") no-repeat; } }
  @media (min-width: 1001px) {
    .wordwheel__incorrect_background {
      background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/medium/background-incorrect.webp") no-repeat; } }
  @media (min-width: 1801px) {
    .wordwheel__incorrect_background {
      background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/large/background-incorrect.webp") no-repeat; } }

.wordwheel__correct_background {
  background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/background-correct.webp") no-repeat; }
  @media (min-width: 768px) {
    .wordwheel__correct_background {
      background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/small/background-correct.webp") no-repeat; } }
  @media (min-width: 1001px) {
    .wordwheel__correct_background {
      background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/medium/background-correct.webp") no-repeat; } }
  @media (min-width: 1801px) {
    .wordwheel__correct_background {
      background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/large/background-correct.webp") no-repeat; } }

.wordwheel__green_background {
  background: green;
  text-transform: uppercase; }

.wordwheel__red_background {
  background: red;
  text-transform: uppercase; }

#main {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center; }

.reaction-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 109vw;
  opacity: 0.4;
  background-size: cover;
  -webkit-transition: background 1.5s linear;
  -moz-transition: background 1.5s linear;
  -o-transition: background 1.5s linear;
  -ms-transition: background 1.5s linear;
  transition: background 1.5s linear; }

.wordwheel__arrow-pointer, .wordwheel__arrow-pointer--incorrect, .wordwheel__arrow-pointer--correct {
  width: calc(55vw * 0.6);
  height: 120px;
  background: white;
  position: absolute;
  right: calc(-50px - 55vw * 0.6);
  top: 43%;
  background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/pointer.webp");
  box-shadow: 0px 0px 0px 0.1vw black, 0px 0px 0px 0.1vw black; }
  @media (min-width: 768px) {
    .wordwheel__arrow-pointer, .wordwheel__arrow-pointer--incorrect, .wordwheel__arrow-pointer--correct {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/small/pointer.webp"); } }
  @media (min-width: 1001px) {
    .wordwheel__arrow-pointer, .wordwheel__arrow-pointer--incorrect, .wordwheel__arrow-pointer--correct {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/medium/pointer.webp"); } }
  @media (min-width: 1801px) {
    .wordwheel__arrow-pointer, .wordwheel__arrow-pointer--incorrect, .wordwheel__arrow-pointer--correct {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/large/pointer.webp"); } }
  .wordwheel__arrow-pointer .wordwheel__arrow-title, .wordwheel__arrow-pointer--incorrect .wordwheel__arrow-title, .wordwheel__arrow-pointer--correct .wordwheel__arrow-title {
    color: black;
    font-size: 40px;
    margin-right: 20px;
    text-align: right;
    margin-block-end: 0px;
    padding-top: 6px; }
  .wordwheel__arrow-pointer .wordwheel__arrow-desc, .wordwheel__arrow-pointer--incorrect .wordwheel__arrow-desc, .wordwheel__arrow-pointer--correct .wordwheel__arrow-desc {
    color: black;
    font-size: 18px;
    text-align: right;
    margin-right: 20px; }
  .wordwheel__arrow-pointer .wordwheel__arrow-desc-span, .wordwheel__arrow-pointer--incorrect .wordwheel__arrow-desc-span, .wordwheel__arrow-pointer--correct .wordwheel__arrow-desc-span {
    color: blueviolet;
    font-size: 25px; }

.wordwheel__arrow-pointer--incorrect {
  background: red; }

.wordwheel__arrow-pointer--correct {
  background: green; }

.wordwheel__arrow-left-point, .wordwheel__arrow-left-point--incorrect, .wordwheel__arrow-left-point--correct {
  width: 86px;
  height: 86px;
  background: white;
  position: absolute;
  right: -92px;
  top: calc(43% + 17px);
  transform: rotate(-45deg);
  border-right: 0px;
  border-bottom: 0px;
  background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/pointer.webp") no-repeat;
  -webkit-box-shadow: -38px 8px 33px -23px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: -38px 8px 33px -23px rgba(0, 0, 0, 0.51);
  box-shadow: -38px 8px 33px -23px rgba(0, 0, 0, 0.51); }
  @media (min-width: 768px) {
    .wordwheel__arrow-left-point, .wordwheel__arrow-left-point--incorrect, .wordwheel__arrow-left-point--correct {
      background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/small/pointer.webp") no-repeat; } }
  @media (min-width: 1001px) {
    .wordwheel__arrow-left-point, .wordwheel__arrow-left-point--incorrect, .wordwheel__arrow-left-point--correct {
      background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/medium/pointer.webp") no-repeat; } }
  @media (min-width: 1801px) {
    .wordwheel__arrow-left-point, .wordwheel__arrow-left-point--incorrect, .wordwheel__arrow-left-point--correct {
      background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/large/pointer.webp") no-repeat; } }

.wordwheel__arrow-left-point--incorrect {
  background: red; }

.wordwheel__arrow-left-point--correct {
  background: green; }

.wordwheel__spinner {
  --spin-time: 1000;
  position: relative;
  left: -40vw;
  width: 55vw;
  height: 55vw;
  padding-bottom: 40%;
  margin: 2rem 2rem; }
  .wordwheel__spinner:before, .wordwheel__spinner:after {
    content: '';
    display: block;
    position: absolute; }
  .wordwheel__spinner-center {
    position: absolute;
    width: 60%;
    height: 60%;
    left: 20%;
    top: 20%;
    background-color: #4a2222;
    border: 15px solid #080808;
    border-radius: 400px;
    background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/wheel-background-red.webp") no-repeat; }
    @media (min-width: 768px) {
      .wordwheel__spinner-center {
        background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/small/wheel-background-red.webp") no-repeat; } }
    @media (min-width: 1001px) {
      .wordwheel__spinner-center {
        background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/medium/wheel-background-red.webp") no-repeat; } }
    @media (min-width: 1801px) {
      .wordwheel__spinner-center {
        background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/large/wheel-background-red.webp") no-repeat; } }
  .wordwheel__spinner-list {
    background-color: white;
    transition: transform calc(var(--spin-time) * 1ms) ease-out;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
    margin: 0px;
    padding: 0px;
    box-shadow: 0px 0px 0px 0.8vw black, 0px 0px 0px 0.9vw black; }
    .wordwheel__spinner-list:after {
      content: '';
      display: block;
      width: 1.7vw;
      height: 1.7vw;
      background-color: #3c3434;
      position: absolute;
      border-radius: 50%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto; }
  .wordwheel__spinner-slice {
    position: absolute;
    display: flex;
    top: 50%;
    left: 50%;
    transform-origin: left;
    list-style: none;
    height: 14.39897vw;
    width: 27.5vw;
    justify-content: flex-end;
    align-items: center;
    z-index: -1; }
    .wordwheel__spinner-slice-text {
      z-index: 1;
      text-align: right;
      padding: 0.5em 0.5em 0.5em 2.2em;
      max-width: 100%;
      user-select: none;
      color: darkgray;
      font-size: calc(55vw / 8);
      font-family: Calibri, sans-serif;
      backface-visibility: visible;
      font-weight: 600;
      transform: translateY(-10%) rotate(-10deg); }
  .wordwheel__spinner-lever {
    top: 0;
    bottom: 0;
    margin: auto;
    left: calc(100% + 0.4vw);
    width: 20vw;
    height: 20vw;
    text-align: center; }
    .wordwheel__spinner-lever-button {
      position: absolute;
      border: 0;
      background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/red_push_btn.webp") no-repeat;
      padding: 0;
      width: 135px;
      height: 106px;
      border-radius: 100%;
      cursor: pointer;
      top: calc(54% - 0.9vw);
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1; }
      @media (min-width: 768px) {
        .wordwheel__spinner-lever-button {
          background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/small/red_push_btn.webp") no-repeat; } }
      @media (min-width: 1001px) {
        .wordwheel__spinner-lever-button {
          background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/medium/red_push_btn.webp") no-repeat; } }
      @media (min-width: 1801px) {
        .wordwheel__spinner-lever-button {
          background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/large/red_push_btn.webp") no-repeat; } }
      .wordwheel__spinner-lever-button:active {
        background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/red_push_btn-pushed_in.webp") no-repeat; }
        @media (min-width: 768px) {
          .wordwheel__spinner-lever-button:active {
            background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/small/red_push_btn-pushed_in.webp") no-repeat; } }
        @media (min-width: 1001px) {
          .wordwheel__spinner-lever-button:active {
            background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/medium/red_push_btn-pushed_in.webp") no-repeat; } }
        @media (min-width: 1801px) {
          .wordwheel__spinner-lever-button:active {
            background: url("https://ddzs4xvq8xxep.cloudfront.net/games/WordWheel/images/large/red_push_btn-pushed_in.webp") no-repeat; } }
      .is-spinning .wordwheel__spinner-lever-button {
        animation: moveLever 3000ms ease-in-out; }

@keyframes moveLever {
  0%, 100% {
    transform: translateY(0%); }
  10% {
    transform: translateY(240%); } }

.wordwheel__spinner-slice:nth-of-type(1) {
  transform: translateY(-50%) rotate(30deg); }

.wordwheel__spinner-slice:nth-of-type(2) {
  transform: translateY(-50%) rotate(60deg); }

.wordwheel__spinner-slice:nth-of-type(3) {
  transform: translateY(-50%) rotate(90deg); }

.wordwheel__spinner-slice:nth-of-type(4) {
  transform: translateY(-50%) rotate(120deg); }

.wordwheel__spinner-slice:nth-of-type(5) {
  transform: translateY(-50%) rotate(150deg); }

.wordwheel__spinner-slice:nth-of-type(6) {
  transform: translateY(-50%) rotate(180deg); }

.wordwheel__spinner-slice:nth-of-type(7) {
  transform: translateY(-50%) rotate(210deg); }

.wordwheel__spinner-slice:nth-of-type(8) {
  transform: translateY(-50%) rotate(240deg); }

.wordwheel__spinner-slice:nth-of-type(9) {
  transform: translateY(-50%) rotate(270deg); }

.wordwheel__spinner-slice:nth-of-type(10) {
  transform: translateY(-50%) rotate(300deg); }

.wordwheel__spinner-slice:nth-of-type(11) {
  transform: translateY(-50%) rotate(330deg); }

.wordwheel__spinner-slice:nth-of-type(12) {
  transform: translateY(-50%) rotate(360deg); }

.word-wheel .gameplay-container {
  transform: translate(-1500px, 0px);
  /********* end */ }
  .word-wheel .gameplay-container .wheel-container {
    position: absolute;
    right: 20vw;
    top: 0;
    margin-top: -35px; }
  .word-wheel .gameplay-container .wheel-wrapper {
    aspect-ratio: 1 / 1;
    width: 55vw;
    margin-top: -15vh;
    position: relative;
    left: -25vw;
    top: -1%;
    transform: rotate(60deg); }
  .word-wheel .gameplay-container .wheel-pointer {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 60px;
    z-index: 10;
    cursor: pointer; }
  .word-wheel .gameplay-container .wheel-bg {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    overflow: hidden;
    background: #fff; }
  .word-wheel .gameplay-container .wheel-bg::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 1 / 1;
    width: 65%;
    border-radius: 50%;
    border: 10px solid #000000;
    background-color: var(--wheel-color);
    z-index: 1; }
  .word-wheel .gameplay-container .wheel-bg.freeze {
    transition: none;
    background: red; }
  .word-wheel .gameplay-container .letter-list {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center; }
  .word-wheel .gameplay-container .letter-container-wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    height: 50%; }
  .word-wheel .gameplay-container .letter-container {
    width: 100%;
    height: 100%;
    transform-origin: bottom; }
  .word-wheel .gameplay-container .letter-container .letter {
    font-weight: bold;
    transform: rotate(-90deg);
    transition: filter 1s cubic-bezier(0.8, 0, 0.3, 1);
    font-size: clamp(2em, 6vw, 6em);
    padding: 0.8em;
    opacity: 0.6; }
  @media (max-width: 576px) {
    .word-wheel .gameplay-container .letter-container .letter {
      font-size: 2.5em;
      padding: 0em; } }
  @media (min-width: 576px) and (max-width: 768px) {
    .word-wheel .gameplay-container .letter-container .letter {
      font-size: clamp(1.5em, 6vw, 4em);
      padding: 0.1em; } }
  @media (min-width: 768px) and (max-width: 992px) {
    .word-wheel .gameplay-container .letter-container .letter {
      font-size: clamp(2em, 8vw, 3em);
      padding: 0.2em; } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .word-wheel .gameplay-container .letter-container .letter {
      font-size: clamp(1.5em, 10vw, 4.5em);
      padding: 0.2em; } }
  @media (min-width: 1201px) and (max-width: 1249px) {
    .word-wheel .gameplay-container .letter-container .letter {
      font-size: clamp(1.5em, 10vw, 5em);
      padding: 0.2em; } }
  @media (min-width: 1250px) {
    .word-wheel .gameplay-container .letter-container .letter {
      font-size: 6em;
      padding: 0.1em;
      opacity: 0.6; } }
  .word-wheel .gameplay-container .playbutton {
    position: absolute;
    bottom: 15px;
    left: 50%;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 0; }
  .word-wheel .gameplay-container .pointer {
    position: absolute;
    left: 50%;
    top: 50%; }

.word-wheel .current-letter {
  opacity: 1 !important; }

.word-wheel .q-field__inner input {
  padding: 15px 10px;
  font-size: 1.3rem;
  font-weight: 600; }

.word-wheel .bonus-prompt {
  min-width: 65vw;
  min-height: 33vh; }
  .word-wheel .bonus-prompt h3 {
    margin: 0 0 1.5rem 0; }
  .word-wheel .bonus-prompt .bonusInput {
    padding: 15px; }

.shapeshooter__container {
  width: 100%;
  height: 100vh; }

.shape-shooter-card {
  overflow: hidden !important; }
  .shape-shooter-card .answer-modal {
    overflow: hidden !important; }

.shapeAnswerRow {
  display: grid;
  grid-template-columns: 1fr 9fr;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  width: 100%;
  padding: 0.3rem;
  vertical-align: top;
  border-radius: 3px;
  -webkit-transition: background-color 0.5s ease-out;
  -ms-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out; }
  .shapeAnswerRow .col {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .shapeAnswerRow:not(:last-child) {
    padding-bottom: 1px; }

.shape-shooter-card.q-card {
  box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39); }
  .shape-shooter-card.q-card h1 {
    font-size: 16px;
    text-align: center;
    font-weight: 700;
    color: #000;
    margin: 25px 0 25px 0; }
    @media (min-width: 320px) {
      .shape-shooter-card.q-card h1 {
        font-size: calc(16px + 16 * (100vw - 320px) / 880); } }
    @media (min-width: 1200px) {
      .shape-shooter-card.q-card h1 {
        font-size: 32px; } }
  .shape-shooter-card.q-card p {
    font-size: 14px;
    color: #000;
    margin-bottom: 25px; }
    @media (min-width: 320px) {
      .shape-shooter-card.q-card p {
        font-size: calc(14px + 4 * (100vw - 320px) / 880); } }
    @media (min-width: 1200px) {
      .shape-shooter-card.q-card p {
        font-size: 18px; } }

.shapeshooter__paddle {
  background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/blue-banner.webp");
  background-repeat: no-repeat;
  width: 200px;
  height: 420px;
  bottom: 236px;
  opacity: 0;
  z-index: 5;
  background-size: contain;
  display: flex;
  justify-content: center;
  position: absolute;
  padding: 15px; }
  @media (min-width: 768px) {
    .shapeshooter__paddle {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/small/blue-banner.webp"); } }
  @media (min-width: 1001px) {
    .shapeshooter__paddle {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/medium/blue-banner.webp"); } }
  @media (min-width: 1801px) {
    .shapeshooter__paddle {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/large/blue-banner.webp"); } }
  .shapeshooter__paddle .shapeshooter__symbol:hover {
    opacity: 1;
    transform: scale(1) !important;
    cursor: default !important; }

.answer_hint_text {
  font-weight: 700;
  font-size: 1.2em;
  text-align: right;
  height: 100%;
  margin-right: 1.5rem;
  text-transform: uppercase;
  display: flex;
  align-items: center; }

.category-answer-row {
  background-color: white;
  border-radius: 5px;
  display: grid;
  grid-auto-columns: minmax(40px, 1fr);
  grid-auto-flow: column; }
  .category-answer-row.correct {
    background-color: rgba(173, 255, 47, 0.75); }
  .category-answer-row.incorrect {
    background-color: rgba(255, 0, 0, 0.75); }
  .category-answer-row .answer-container {
    display: flex;
    align-items: center;
    justify-content: center; }
    .category-answer-row .answer-container .answer-symbol {
      align-items: center;
      justify-content: center; }
    .category-answer-row .answer-container.answer-color {
      border: 0;
      width: 80%;
      height: auto;
      border-radius: 100%;
      margin: 1rem 0 1rem 1rem;
      align-items: center;
      justify-content: center; }

.shapeshooter__symbol {
  background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/shapes/apple.webp");
  background-repeat: no-repeat;
  width: 120px;
  height: 120px;
  background-size: contain;
  border: 0;
  background-color: transparent;
  margin-top: 55px;
  transition: all 0.2s ease-in-out; }
  .shapeshooter__symbol:not(.answer_hint):hover {
    transform: scale(1.3);
    cursor: pointer; }
  .shapeshooter__symbol.answer_hint {
    background-position: center;
    padding: 10px;
    opacity: 0.5; }

.answer_hint, .answer-symbol {
  margin-top: 0; }

.shapeshooter__sky {
  background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/background.webp");
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 536px;
  z-index: 0;
  background-size: cover; }
  @media (min-width: 768px) {
    .shapeshooter__sky {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/small/background.webp"); } }
  @media (min-width: 1001px) {
    .shapeshooter__sky {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/medium/background.webp"); } }
  @media (min-width: 1801px) {
    .shapeshooter__sky {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/large/background.webp"); } }

.shapeshooter__backmountains {
  background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/back-mountain.webp");
  background-repeat: repeat-x;
  width: 100%;
  height: 410px;
  position: absolute;
  bottom: 236px;
  z-index: 2;
  background-size: cover; }
  @media (min-width: 768px) {
    .shapeshooter__backmountains {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/small/back-mountain.webp"); } }
  @media (min-width: 1001px) {
    .shapeshooter__backmountains {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/medium/back-mountain.webp"); } }
  @media (min-width: 1801px) {
    .shapeshooter__backmountains {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/large/back-mountain.webp"); } }

.shapeshooter__mountains {
  background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/front-mountain.webp");
  background-repeat: repeat-x;
  width: 100%;
  height: 410px;
  position: absolute;
  bottom: 236px;
  z-index: 2;
  background-size: cover; }
  @media (min-width: 768px) {
    .shapeshooter__mountains {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/small/front-mountain.webp"); } }
  @media (min-width: 1001px) {
    .shapeshooter__mountains {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/medium/front-mountain.webp"); } }
  @media (min-width: 1801px) {
    .shapeshooter__mountains {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/large/front-mountain.webp"); } }

.shapeshooter__bushes {
  background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/front-grass.webp");
  background-repeat: repeat-x;
  width: 100%;
  height: 301px;
  position: absolute;
  bottom: 10vh;
  z-index: 4; }
  @media (min-width: 768px) {
    .shapeshooter__bushes {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/small/front-grass.webp"); } }
  @media (min-width: 1001px) {
    .shapeshooter__bushes {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/medium/front-grass.webp"); } }
  @media (min-width: 1801px) {
    .shapeshooter__bushes {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/large/front-grass.webp"); } }

.shapeshooter__wall {
  background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/stones.webp");
  background-repeat: repeat-x;
  width: 100%;
  height: 356px;
  position: absolute;
  bottom: -100px;
  z-index: 6;
  background-size: cover; }
  @media (min-width: 768px) {
    .shapeshooter__wall {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/small/stones.webp"); } }
  @media (min-width: 1001px) {
    .shapeshooter__wall {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/medium/stones.webp"); } }
  @media (min-width: 1801px) {
    .shapeshooter__wall {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/ShapeShooter/images/large/stones.webp"); } }

.answer-container {
  background-position: center;
  aspect-ratio: 1/1;
  max-width: 140px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .answer-container:hover {
    cursor: pointer; }

.group-hints {
  position: absolute;
  top: 0px;
  left: 15vw;
  z-index: 5000;
  background-color: #ffffff;
  color: #000000;
  padding: 10px 15px 10px 15px;
  min-width: 19vw;
  border-radius: 5px;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.3em;
  box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39); }
  .group-hints strong {
    font-weight: 700;
    text-transform: uppercase; }

.task-hint {
  display: grid;
  grid-template-columns: 1fr 8fr;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 15vw;
  z-index: 5000;
  background-color: #ffffff;
  color: #000000;
  padding: 10px 15px 10px 5px;
  min-width: 19vw;
  border-radius: 5px;
  box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39); }
  .task-hint .task-hint__text {
    font-size: 1.3em;
    font-weight: 400;
    margin: 0; }
    .task-hint .task-hint__text strong {
      font-weight: 700;
      text-transform: uppercase; }

.background-grey {
  background-color: #999999; }

.background-orange {
  background-color: #E69F00; }

.background-green {
  background-color: #009E73; }

.background-yellow {
  background-color: #F0E442; }

.background-blue {
  background-color: #0072B2; }

.background-red {
  background-color: #d61b1b; }

.background-purple {
  background-color: #CC79A7; }

.HideOut.modal .content {
  background-position-y: -54px;
  background-size: cover; }

.hideout .gameplay-container {
  transform: translate(0px, 2000px); }

.hideout__container {
  width: 100vw;
  height: 100vh;
  background-size: contain;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden; }

.questionModal {
  height: 500px; }

.hideout__question_half_pop-up {
  overflow: visible !important;
  max-height: 1000px;
  background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/HideOut/images/question_half_pop-up.webp");
  background-repeat: no-repeat;
  text-align: center;
  background-size: contain;
  aspect-ratio: 262/119;
  width: 50vw;
  max-width: none;
  box-shadow: none !important;
  margin-top: 22%; }
  @media (min-width: 768px) {
    .hideout__question_half_pop-up {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/HideOut/images/small/question_half_pop-up.webp"); } }
  @media (min-width: 1001px) {
    .hideout__question_half_pop-up {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/HideOut/images/medium/question_half_pop-up.webp"); } }
  @media (min-width: 1801px) {
    .hideout__question_half_pop-up {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/HideOut/images/large/question_half_pop-up.webp"); } }
  .hideout__question_half_pop-up .q-btn {
    border: 2px solid black;
    font-weight: 700; }

.hideout__hole_container {
  width: 50%;
  height: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 5vh auto auto;
  max-width: 620px;
  position: absolute;
  top: 35vh;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: left 0.5s ease-in-out; }

.hideout__bonus_container {
  background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/HideOut/images/score_screen.webp");
  aspect-ratio: 399/523;
  width: calc(45vw);
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 35vh;
  right: -100%;
  /* starts offscreen */
  transform: translateY(-50%);
  transition: right 0.5s ease-in-out; }
  @media (min-width: 768px) {
    .hideout__bonus_container {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/HideOut/images/small/score_screen.webp"); } }
  @media (min-width: 1001px) {
    .hideout__bonus_container {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/HideOut/images/medium/score_screen.webp"); } }
  @media (min-width: 1801px) {
    .hideout__bonus_container {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/HideOut/images/large/score_screen.webp"); } }
  @media (min-width: 1230px) {
    .hideout__bonus_container {
      margin-top: calc(5vh);
      width: calc(40vw);
      max-width: 500px; } }
  @media (min-width: 1201px) {
    .hideout__bonus_container {
      margin-top: calc(5vh); } }
  @media (max-width: 1200px) {
    .hideout__bonus_container {
      width: calc(37vw); } }
  @media (max-width: 1000px) {
    .hideout__bonus_container {
      width: calc(47vw); } }
  .hideout__bonus_container .answers {
    padding: 27% 15% 10% 15%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: calc(10px + 0.200625vw);
    margin-top: 10%;
    text-align: center; }
    .hideout__bonus_container .answers .answer button {
      color: #363636;
      border: 0px;
      background: transparent;
      text-transform: uppercase;
      transition: opacity 0.2s ease-out;
      line-height: 25px;
      transition: all 0.3s ease;
      border-radius: 5px;
      border: 3px dotted rgba(0, 0, 0, 0);
      font-size: calc(10px + 0.500625vw);
      font-family: "Nunito", sans-serif;
      font-weight: 700 !important; }
      .hideout__bonus_container .answers .answer button:hover {
        -webkit-transform: scale(1.1);
        transform: scale(1.1); }
      .hideout__bonus_container .answers .answer button:hover {
        cursor: pointer; }
      .hideout__bonus_container .answers .answer button:hover {
        border: 3px dotted #000; }
    .hideout__bonus_container .answers .answer.answered button {
      position: relative; }
      .hideout__bonus_container .answers .answer.answered button:after {
        content: "";
        position: absolute;
        top: 45%;
        left: 0;
        width: 100%;
        height: 3px;
        background: black; }
      .hideout__bonus_container .answers .answer.answered button:hover {
        border: 0; }
    .hideout__bonus_container .answers .answer.answeredIncorrectly {
      visibility: hidden; }

.hideout_eyes {
  width: 84%;
  height: 84%;
  max-width: 620px;
  position: absolute;
  margin: 15% auto auto 35px;
  background-size: contain;
  background-repeat: no-repeat; }

.hideout_eyes::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/HideOut/images/medium/eyes-open2.webp");
  background-size: contain;
  background-repeat: no-repeat; }

.hideout_eyes::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/HideOut/images/medium/eyes-closed2.webp");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0;
  animation: blinkEyes 18s infinite; }

@keyframes blinkEyes {
  0%, 19.8%, 20.9%, 39.8%, 40.9%, 59.8%, 60.9%, 79.8%, 80.9%, 99% {
    opacity: 0; }
  20%, 20.7%, 40%, 40.7%, 60%, 60.7%, 80%, 80.7%, 99.4% {
    opacity: 1; } }

.q-dialog__inner--minimized > div {
  max-width: none !important; }

.hideout_hole_close {
  width: 100%;
  height: 100%;
  background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/HideOut/images/medium/wood lid.webp");
  background-size: contain;
  margin: auto;
  background-repeat: no-repeat;
  max-width: 620px;
  position: absolute; }

.hideout_hole {
  width: 100%;
  height: 100%;
  background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/HideOut/images/hole.webp");
  background-size: contain;
  margin: auto;
  background-repeat: no-repeat;
  max-width: 620px;
  position: absolute; }
  @media (min-width: 768px) {
    .hideout_hole {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/HideOut/images/small/hole.webp"); } }
  @media (min-width: 1001px) {
    .hideout_hole {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/HideOut/images/medium/hole.webp"); } }
  @media (min-width: 1801px) {
    .hideout_hole {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/HideOut/images/large/hole.webp"); } }

.hideout__popup_title {
  font-size: 70px;
  text-align: center;
  margin-top: 10vh;
  color: black; }

.hideout__popup_desc {
  color: black;
  padding-bottom: 15px; }

.hideout__popup_question_line1, .hideout__popup_question_line2, .hideout__popup_failer_title, .hideout__popup_failer_desc, .hideout__popup_password_title, .hideout__popup_password_desc {
  text-align: left;
  margin-top: 10vh;
  color: black;
  font-size: 1.4rem;
  margin-left: 30px;
  font-family: "Nunito", sans-serif;
  font-weight: 700 !important; }

.hideout__popup_question_line2 {
  font-size: 2rem;
  margin-top: 0px;
  font-family: "Nunito", sans-serif;
  font-weight: 700 !important; }

.hideout__popup_failer_title {
  font-size: 42px;
  margin-top: 10vh;
  font-family: "Nunito", sans-serif;
  font-weight: 700 !important; }

.hideout__popup_failer_desc {
  font-size: 20px;
  margin-top: 8px;
  margin-bottom: 15px; }

.hideout__popup_password_title {
  font-size: 20px;
  margin-top: 10vh; }

.hideout__popup_password_desc {
  font-size: 50px;
  margin-top: 10px; }

.hideout__popup_progress {
  margin: 13px; }

.hideout-fade-enter-active, .hideout-fade-leave-active {
  transition: all .5s; }

.hideout-fade-enter, .hideout-fade-leave-to {
  transform: translateX(100%); }

.instructions .fadeContainer {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%); }

.instructions .fadeOne-enter-active, .instructions .fadeOne-leave-active {
  transition: all .5s ease-out; }

.instructions .fadeOne-enter, .instructions .fadeOne-leave-to {
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  transform: translate(0%, 100%); }

.instructions .fadeTwo-enter-active, .instructions .fadeTwo-leave-active {
  transition: all .5s ease-out; }

.instructions .fadeTwo-enter, .instructions .fadeTwo-leave-to {
  clip-path: polygon(0% 100%, 100% 100%, 0% 100%, 0% 100%);
  transform: translate(0%, -100%); }

.sweet-sampler .gameplay-container {
  transform: translate(-3000px, 0px); }

.sweet-sampler .sweetsampler__question_container {
  transition: all 0.5s; }

.sweetsampler__container {
  width: 100vw;
  height: 85vh;
  background-size: contain;
  align-items: center;
  text-align: center;
  display: flex;
  flex-flow: column; }

.sweetsampler__question_container {
  width: 87vw;
  height: 100vh;
  background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/SweetSampler/images/board.webp");
  background-size: contain;
  margin: auto;
  background-repeat: no-repeat;
  max-width: 1224px;
  position: relative;
  padding: 40px 110px 60px 120px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  background-position: center; }
  .sweetsampler__question_container .row:first-child {
    padding-bottom: 40px; }
    @media screen and (max-width: 768px) {
      .sweetsampler__question_container .row:first-child {
        padding-bottom: 0; } }
  @media (min-width: 768px) {
    .sweetsampler__question_container {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/SweetSampler/images/small/board.webp"); } }
  @media (min-width: 1001px) {
    .sweetsampler__question_container {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/SweetSampler/images/medium/board.webp"); } }
  @media (min-width: 1801px) {
    .sweetsampler__question_container {
      background-image: url("https://ddzs4xvq8xxep.cloudfront.net/games/SweetSampler/images/large/board.webp"); } }
  @media screen and (max-width: 2000px) {
    .sweetsampler__question_container {
      padding: 40px 250px 70px 250px; } }
  @media screen and (max-width: 1244px) {
    .sweetsampler__question_container {
      padding: 40px 70px 70px 70px; } }
  @media screen and (max-width: 768px) {
    .sweetsampler__question_container {
      padding: 60px 50px 70px 50px; } }

.sweetsampler__label_container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center; }

.sweetsampler__answer_container {
  box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 10px 10px 26px 7px rgba(0, 0, 0, 0.39);
  width: 77%;
  height: 70%;
  margin: 5% auto auto;
  max-width: 1024px;
  max-height: 700px;
  position: relative;
  z-index: 5000;
  padding: 20px 60px 40px 60px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  background-position: center;
  border-radius: 5px;
  background-image: linear-gradient(#f1e5e5, #d0d0d0); }
  @media screen and (max-width: 1244px) {
    .sweetsampler__answer_container {
      padding: 20px 40px 40px 40px; } }
  @media screen and (max-width: 768px) {
    .sweetsampler__answer_container {
      padding: 20px 30px 80px 30px; } }
  .sweetsampler__answer_container:not(.reveal-answers) .bg-green:not(.sweetsampler__candy-command), .sweetsampler__answer_container:not(.reveal-answers) .bg-red {
    background-color: #a2a2a2 !important; }

.sweetsampler__user_answer_container {
  width: 100%;
  height: 50%;
  background-repeat: no-repeat;
  max-width: 1024px;
  position: relative;
  padding: 10px;
  margin: 5% auto 15px;
  color: black;
  background-position: center;
  box-shadow: inset 6px 6px 12px #444, inset -6px -6px 36px #666;
  border-radius: 5px;
  background-image: linear-gradient(#898989, #616161); }
  .sweetsampler__user_answer_container div.label {
    font-size: 1.2rem;
    font-family: "NunitoBlack", sans-serif; }
  @media screen and (max-width: 1244px) {
    .sweetsampler__user_answer_container div.label {
      font-size: 1.1rem; } }
  @media screen and (max-width: 768px) {
    .sweetsampler__user_answer_container {
      margin-top: 5px; }
      .sweetsampler__user_answer_container div.label {
        font-size: 1rem; } }
  @media screen and (max-width: 1244px) {
    .sweetsampler__user_answer_container {
      padding: 20px 80px 40px 80px; } }
  @media screen and (max-width: 768px) {
    .sweetsampler__user_answer_container {
      padding: 80px 60px 80px 60px; } }

.sweetsampler__candy-gray {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 60px;
  width: 60px; }
  @media screen and (max-width: 1244px) {
    .sweetsampler__candy-gray {
      height: 50px;
      width: 50px; } }
  @media screen and (max-width: 768px) {
    .sweetsampler__candy-gray {
      height: 1.6rem;
      width: 1.6rem; } }

.sweetsampler__candy-command {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 80px;
  width: 80px; }
  @media screen and (max-width: 1244px) {
    .sweetsampler__candy-command {
      height: 70px;
      width: 70px; } }
  @media screen and (max-width: 768px) {
    .sweetsampler__candy-command {
      height: 92%;
      width: 92%;
      text-align: center;
      line-height: 0.1rem;
      font-size: 0.7rem; } }

.sweetsampler__progress {
  margin: 10px 100px 10px 100px;
  padding: 0 20px 0 20px;
  width: 80vw;
  position: absolute;
  bottom: -35px;
  max-width: 860px; }

.sweetsampler__divot {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 160px;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .sweetsampler__divot {
      height: 90px; } }

.sweetsampler__candy {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: all 0.2s ease-out;
  height: 120px;
  width: 100%; }
  @media screen and (max-width: 1244px) {
    .sweetsampler__candy {
      height: 80px; } }
  @media screen and (max-width: 768px) {
    .sweetsampler__candy {
      height: 50px; } }
  .sweetsampler__candy:active {
    transform: scale(1.1); }

.sweetsampler__action {
  top: 0;
  bottom: 0;
  margin: auto;
  left: calc(100% + 0.4vw);
  width: 20vw;
  height: 20vw;
  text-align: center; }

.sweetsampler__action-button {
  border: 0;
  background: url("https://ddzs4xvq8xxep.cloudfront.net/games/SweetSampler/images/red_push_btn.webp") no-repeat;
  padding: 0;
  width: 135px;
  height: 106px;
  border-radius: 100%;
  cursor: pointer;
  top: calc(25% - 0.9vw);
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1; }
  @media (min-width: 768px) {
    .sweetsampler__action-button {
      background: url("https://ddzs4xvq8xxep.cloudfront.net/games/SweetSampler/images/small/red_push_btn.webp") no-repeat; } }
  @media (min-width: 1001px) {
    .sweetsampler__action-button {
      background: url("https://ddzs4xvq8xxep.cloudfront.net/games/SweetSampler/images/medium/red_push_btn.webp") no-repeat; } }
  @media (min-width: 1801px) {
    .sweetsampler__action-button {
      background: url("https://ddzs4xvq8xxep.cloudfront.net/games/SweetSampler/images/large/red_push_btn.webp") no-repeat; } }

.q-linear-progress {
  border: 2px solid #fff;
  padding: 4px 8px 4px 4px;
  min-height: 50px; }
  .q-linear-progress .q-linear-progress__model {
    left: 10px;
    max-height: 25px !important;
    max-width: 97.5%;
    background-color: #fff;
    top: 23%;
    transform: translateY(-23%); }

.sweetsampler__user_answer_row {
  padding-left: 1.5rem; }

.sweetsampler__user_answer_wrapper {
  margin-right: 1rem; }

.answerCorrect {
  color: #003a08; }

.answerIncorrect {
  color: #d61b1b; }

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .4s ease-out; }

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1); }

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(50px);
  opacity: 0; }

.show-orc-enter-active {
  transition: top 0.8s ease 0.5s, opacity 0.3s ease 0s;
  top: 0px;
  opacity: 1; }

.show-orc-leave-active {
  transition: top 0.8s ease 0.5s, opacity 0.3s ease 0s;
  top: -3000px;
  opacity: 1; }

.show-orc-enter, .show-orc-leave-to {
  opacity: 0;
  top: 0; }

.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: zoom; }

.zoom-leave-active {
  animation-direction: reverse; }

.zoom-small-enter-active,
.zoom-small-leave-active {
  animation-duration: 0.7s;
  animation-fill-mode: both;
  animation-name: zoom-small; }

.zoom-small-leave-active {
  animation-direction: reverse; }

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 1; } }

@keyframes zoom-small {
  from {
    opacity: 0;
    transform: scale3d(0.7, 0.7, 0.7); }
  100% {
    opacity: 1; } }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s linear; }

.fade-enter, .fade-leave-to {
  opacity: 0; }

@-webkit-keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes hvr-icon-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0); }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0); } }

@keyframes hvr-icon-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0); }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0); } }

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1); }
  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    transform: scale3d(1, 1, 1); } }

.tada {
  animation-name: tada; }

@-webkit-keyframes flash {
  0% {
    opacity: 1; }
  50% {
    opacity: .7; }
  100% {
    opacity: 1; } }

@keyframes flash {
  0% {
    opacity: 1; }
  50% {
    opacity: .7; }
  100% {
    opacity: 1; } }

.bounce-enter-active,
.bounce-leave-active {
  animation-duration: 1s;
  animation-fill-mode: both; }

.bounce-enter-active {
  animation-name: bounceIn; }

.bounce-leave-active {
  animation-name: bounceOut; }

@keyframes bounceIn {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-50px); }
  60% {
    transform: translateY(-10px); } }

@keyframes bounceOut {
  0% {
    transform: translateX(0); }
  20% {
    transform: translateX(-200px); }
  50%,
  100% {
    transform: translateX(2000px); } }

.login-page {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  max-width: 500px;
  height: 100vh;
  margin: 0 auto; }
  .login-page .error {
    width: 100%;
    justify-self: baseline;
    border-radius: 5px; }
  .login-page input {
    font-family: "Nunito", sans-serif;
    margin-bottom: 5px; }
  .login-page input, .login-page button {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    display: block;
    padding: 15px;
    border-radius: 5px;
    border: 2px solid #999999; }
    @media (min-width: 360px) {
      .login-page input, .login-page button {
        font-size: calc(16px + 2 * (100vw - 360px) / 840); } }
    @media (min-width: 1200px) {
      .login-page input, .login-page button {
        font-size: 18px; } }
  .login-page h3 {
    color: #000;
    text-align: center;
    margin-bottom: 4rem; }
  .login-page h6 {
    margin: 20px 0 4px 0; }
  .login-page button {
    border: 0px;
    background: transparent;
    text-transform: uppercase;
    transition: opacity 0.2s ease-out;
    line-height: 25px;
    transition: all 0.3s ease;
    margin: 0 auto;
    margin-top: 15px; }
    .login-page button:hover {
      -webkit-transform: scale(1.1);
      transform: scale(1.1); }
    .login-page button:hover {
      cursor: pointer; }
  .login-page form, .login-page input {
    width: 100%; }
  .login-page .loader-general {
    position: relative;
    width: 100%; }
    .login-page .loader-general h3 {
      display: inline-block;
      margin-top: -10px; }
    .login-page .loader-general svg {
      position: absolute;
      top: -30px;
      display: inline-block;
      max-width: 80px;
      margin-left: 20px; }

.error {
  display: block;
  width: 100%;
  padding: 15px;
  color: #721c24;
  background-color: #f8d7da; }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s, margin-top 0.4s; }

.fade-enter, .fade-leave-to {
  opacity: 0;
  margin-top: 3rem; }

.paper-item {
  transition: transform 0.5s ease; }

.paper-enter-active, .paper-leave-active {
  transition: all 0.5s ease; }

.paper-leave-to {
  transform: translateX(-100%) rotate(-30deg);
  opacity: 0; }
