.login-page {
  display:flex;
  justify-content:center;
  flex-wrap: wrap;
  align-items:center;
  .error {
    width: 100%;
    justify-self: baseline;
    border-radius: 5px;
  }
  max-width: 500px;
  height: 100vh;
  margin: 0 auto;

  input {
    font-family: "Nunito", sans-serif;
    margin-bottom: 5px;
  }
  input, button {
    @include base-type;
    @include fluid-property(font-size, 360px, 1200px, 16px, 18px);

    display: block;
    padding: 15px;
    border-radius: 5px;
    border: 2px solid #999999;
  }
  h3 {
    color: #000;
    text-align: center;
    margin-bottom: 4rem;
  }

  h6 {
    margin: 20px 0 4px 0;
  }

  button {
    @include button;
    @include button-add-hover($light-color, $dark-color);
    margin: 0 auto;
    margin-top: 15px;

  }
  form, input {
    width: 100%;
  }

  .loader-general {
    position: relative;
    width: 100%;
    h3 {
      display: inline-block;

      margin-top: -10px;
    }
    svg {
      position: absolute;
      top: -30px;
      display: inline-block;
      max-width: 80px;
      margin-left: 20px;
    }
  }
}
