#the-decoder {
  height: 100%;

  .crack-code {
    .code-header {
      max-height: 82px;
    }
    .code-wrapper {
      margin-top: 30px;
      height: 56px;
      font-size: 50px;
      line-height: 1;
      color: white;
      background-color: black;

      > span {
        position: relative;
      }

      .label {
        position: absolute;
        bottom: 100%;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        color: black;
      }
      > span:nth-of-type(2) .label {
        padding-left: 10px;
      }

      .characters {
        position: relative;
        display: inline-block;
        margin: 3px 3px;
        padding: 0 5px;
        line-height: 1;

        .background {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          perspective: 800px;
          backface-visibility: hidden;
          transform: rotate3D(0, 1, 0, 90deg);
          transition: 300ms background-color cubic-bezier(0.55, 0, 1, 0.45),
            500ms transform cubic-bezier(0.32, 0, 0.67, 0);
        }
        > :not(.background) {
          position: relative;
          z-index: 1;
        }
        &.correct .background {
          transform: rotate3D(0, 1, 0, 0deg);
          background-color: #1f19;
        }
        &.incorrect .background {
          transform: rotate3D(0, 1, 0, 0deg);
          background-color: #f119;
        }
      }

      input {
        width: 0;
        opacity: 0;
      }
    }

    .button-wrapper button {
      &:nth-child(2) {
        margin-left: auto;
      }
      &:nth-child(3) {
        margin-left: 10px;
      }
    }
  }

  .find-code {
    background-size: cover;
    cursor: none;

    > .symbol {
      position: relative;
      display: inline-flex;
      width: 20%;
      min-width: 50px;

      img {
        margin: auto;
        width: 100%;
        height: auto;
        object-fit: scale-down;

        &.animate {
          animation: pulse 1000ms infinite;
        }
      }
    }

    .cursor {
      position: absolute;
      align-self: start;
      justify-self: start;
      width: auto !important;
      transform: translate(-50%, calc(-50% + 15px));
      pointer-events: none;
    }
  }
  @supports (display: grid) {
    .find-code {
      display: grid;
      grid-template-columns: repeat(5, minmax(auto, 15vw));
      grid-template-rows: repeat(3, minmax(auto, 15vw));
      justify-items: center;
      align-items: center;
      justify-content: center;
      align-content: center;

      .symbol {
        padding: 5px;
        width: 100%;
      }
    }

    @media (orientation: portrait) {
      .find-code {
        grid-template-columns: repeat(3, minmax(auto, 15vh));
        grid-template-rows: repeat(5, minmax(auto, 15vh));
      }
    }
  }

  .keyboards {
    display: flex;
    gap: 5px;
    margin: 15px 0;

    .letters,
    .numbers {
      display: flex;
      flex-flow: wrap;
      gap: 2px;
      justify-content: center;
    }

    .key {
      font-size: 24px;
      height: 40px;
      border-radius: 5px;
      -webkit-transition: all 0.4s ease-out;
      -moz-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
      -o-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out;
      border: 0;
      font-family: "Nunito", sans-serif;
      font-weight: 900;
      &:hover {
        cursor: pointer;
        background-color: #d3d3d3;
        box-shadow: inset 0px 0px 0px 4px #f3f3f3;
        box-sizing: border-box;
      }
    }
    .letters .key {
      width: calc(10% - 3px);
    }
    .numbers .key {
      flex-grow: 1;
    }
    .break {
      visibility: hidden;
      flex-basis: 100%;
    }

    @media (max-width: 449px) {
      flex-direction: column-reverse;

      .numbers {
        .break {
          display: none;
        }

        :nth-child(1) {
          order: 7;
        }
        :nth-child(2) {
          order: 8;
        }
        :nth-child(3) {
          order: 9;
        }
        :nth-child(5) {
          order: 4;
        }
        :nth-child(6) {
          order: 5;
        }
        :nth-child(7) {
          order: 6;
        }
        :nth-child(9) {
          order: 1;
        }
        :nth-child(10) {
          order: 2;
        }
        :nth-child(11) {
          order: 3;
        }
        :nth-child(13) {
          order: 10;
        }
      }
    }
  }

  .note {
    margin: 20px auto 0px auto;
    padding: 25px;
    border-radius: 5px;
    width: 80%;
    max-width: 1200px;
    color: black;
    background-color: #f4e396;
    box-shadow: 2px 3px 22px -4px rgba(0,0,0,0.54);
    -webkit-box-shadow: 2px 3px 22px -4px rgba(0,0,0,0.54);
    -moz-box-shadow: 2px 3px 22px -4px rgba(0,0,0,0.54);

    .stars {
      margin-bottom: 30px;
      padding-bottom: 10px;
      border-bottom: 2px dotted black;
      font-size: 40px;
      text-align: center;
    }

    h1 {
      margin: 0;
      font-size: 50px;
      font-weight: 700;
      line-height: 1;
    }
    h2 {
      margin: 10px 0 10px;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.1;
    }

    .button-wrapper {
      display: flex;
      margin: 20px 0;

      > :first-child {
        margin-right: auto;
      }
      > :last-child {
        margin-left: auto;
      }

      button {
        font-size: 18px;
        height: 40px;
        border-radius: 5px;
        -webkit-transition: all 0.4s ease-out;
        -moz-transition: all 0.4s ease-out;
        -ms-transition: all 0.4s ease-out;
        -o-transition: all 0.4s ease-out;
        transition: all 0.4s ease-out;
        border: 0;
        font-family: "Nunito", sans-serif;
        font-weight: 900;
        &:hover {
          cursor: pointer;
          background-color: #d3d3d3;
          box-shadow: inset 0px 0px 0px 4px #f3f3f3;
          box-sizing: border-box;
        }
      }
    }
  }

  .code-character {
    position: absolute;
    pointer-events: none;
  }
}

// TRANSITIONS

// phase transition for the findCode
.phase-transition-enter-active,
.phase-transition-leave-active {
  transition: color 1.5s; // fake transtion to ensure child transitions run
}

.phase-transition-enter-active .symbol,
.phase-transition-leave-active .symbol {
  transition: opacity 500ms var(--delay), transform 500ms var(--delay) cubic-bezier(0.11, 0, 0.5, 0),
    filter 500ms var(--delay);
}
.phase-transition-enter .symbol,
.phase-transition-leave-to .symbol {
  opacity: 0;
  transform: scale(0.2);
  filter: blur(10px);
}

// phase transition for 'note' style pages
.phase-transition-enter-active.note,
.phase-transition-leave-active.note {
  transition: 650ms transform cubic-bezier(0.65, 0, 0.35, 1), 700ms box-shadow ease-out;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
}
.phase-transition-enter.note,
.phase-transition-leave-to.note {
  transform: translateY(100%);
  box-shadow: 0px -20px 10px rgba(0, 0, 0, 0.1);
}

.fade-enter-active,
.fade-leave-active {
  transition: 300ms opacity ease-out, 400ms filter ease-in;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  filter: blur(2px);
}

// ANIMATIONS

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  0% {
    transform: scale(1);
  }
}
