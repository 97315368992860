.HideOut.modal .content {
  background-position-y: -54px;
  background-size: cover;
}

.hideout {
  .gameplay-container {
    transform: translate(0px, 2000px);
  }
}
.hideout__container {
  width: 100vw;
  height: 100vh;
  background-size: contain;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.questionModal {
    height: 500px;
}
.hideout__question_half_pop-up {
    overflow: visible !important;
    max-height: 1000px;

    @include responsive-bg('games/HideOut/images/', 'question_half_pop-up.webp');
    background-repeat: no-repeat;
    text-align: center;
    background-size: contain;
    aspect-ratio: 262/119;
    width: 50vw;
    max-width: none;
    box-shadow: none !important;
    margin-top: 22%;
    
  .q-btn {
    border: 2px solid black;
    font-weight: 700;
  }
}

.hideout__hole_container {
  width: 50%;
  height: 50%;

  background-size: contain;
  background-repeat: no-repeat;
  margin: 5vh auto auto;
  max-width: 620px;
  position: absolute;
  top: 35vh;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: left 0.5s ease-in-out;
}

.hideout__bonus_container {
  @include responsive-bg('games/HideOut/images/', 'score_screen.webp');

  aspect-ratio: 399/523;
  width: calc(45vw);
  margin: 0 auto;
  @media (min-width: 1230px) {
    margin-top: calc(5vh);
    width: calc(40vw);
    max-width: 500px;
  }
  @media (min-width: 1201px) {
    margin-top: calc(5vh);
  }
  @media (max-width: 1200px) {
    width: calc(37vw);
  }
  // if the browser is smaller than 1000px, set the width to calc(75rem + 3vw)
    @media (max-width: 1000px) {
        width: calc(47vw);
    }
  background-size: cover;
  background-repeat: no-repeat;


  position: absolute;
  top: 35vh;
  right: -100%; /* starts offscreen */
  transform: translateY(-50%);
  transition: right 0.5s ease-in-out;

  .answers {
    padding: 27% 15% 10% 15%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: calc(10px + 0.200625vw);
    margin-top: 10%;
    text-align: center;
    .answer {
      button {
        color: $dark-color;
        @include button;
        @include button-add-hover($light-color, $dark-color);
        border-radius: 5px;
        border: 3px dotted rgba(0,0,0,0);
        &:hover {
          border: 3px dotted #000;
        }
        font-size: calc(10px + 0.500625vw);
        font-family: "Nunito", sans-serif;
        font-weight: 700 !important;
      }
      &.answered {
        button {
          position: relative;
          &:after {
            content: "";
            position: absolute;
            top: 45%;
            left: 0;
            width: 100%;
            height: 3px;
            background: black;
          }

          &:hover {
            border: 0;
          }
        }
      }
      &.answeredIncorrectly {
        visibility: hidden;
      }
    }
  }
}

.hideout_eyes {
  width: 84%;
  height: 84%;
  max-width: 620px;
  position: absolute;
  margin: 15% auto auto 35px;
  background-size: contain;
  background-repeat: no-repeat;
}

.hideout_eyes::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url($cdn_url +'games/HideOut/images/medium/eyes-open2.webp');
  background-size: contain;
  background-repeat: no-repeat;
}

.hideout_eyes::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url($cdn_url +'games/HideOut/images/medium/eyes-closed2.webp');
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0;
  animation: blinkEyes 18s infinite;
}

@keyframes blinkEyes {
  0%, 19.8%, 20.9%, 39.8%, 40.9%, 59.8%, 60.9%, 79.8%, 80.9%, 99% {
    opacity: 0;
  }
  20%, 20.7%, 40%, 40.7%, 60%, 60.7%, 80%, 80.7%, 99.4% {
    opacity: 1;
  }
}


.q-dialog__inner--minimized > div
{
  max-width: none !important;
}



.hideout_hole_close {
  width: 100%;
  height: 100%;

  background-image: url($cdn_url +'games/HideOut/images/medium/wood lid.webp');
  
  background-size: contain;
  margin: auto;
  background-repeat: no-repeat;
  max-width: 620px;
  position: absolute;
}

.hideout_hole {
  width: 100%;
  height: 100%;
  @include responsive-bg('games/HideOut/images/', 'hole.webp');
  background-size: contain;
  margin: auto;
  background-repeat: no-repeat;
  max-width: 620px;
  position: absolute;
}

.hideout__popup_title {
  font-size: 70px;
  text-align: center;
  margin-top: 10vh;
  color: black;
}

.hideout__popup_desc {
  color: black;
  padding-bottom: 15px;
}

.hideout__popup_question_line1 {
  text-align: left;
  margin-top: 10vh;
  color: black;
  font-size: 1.4rem;
  margin-left: 30px;
  font-family: "Nunito", sans-serif;
  font-weight: 700 !important;
}

.hideout__popup_question_line2 {
  @extend .hideout__popup_question_line1;
  font-size: 2rem;
  margin-top: 0px;
  font-family: "Nunito", sans-serif;
  font-weight: 700 !important;

}

.hideout__popup_failer_title {
  @extend .hideout__popup_question_line1;
  font-size: 42px;
  margin-top: 10vh;
  font-family: "Nunito", sans-serif;
  font-weight: 700 !important;

}

.hideout__popup_failer_desc {
  @extend .hideout__popup_question_line1;
  font-size: 20px;
  margin-top: 8px;
  margin-bottom: 15px;
}

.hideout__popup_password_title {
  @extend .hideout__popup_question_line1;
  font-size: 20px;
  margin-top: 10vh;
}

.hideout__popup_password_desc {
  @extend .hideout__popup_question_line1;
  font-size: 50px;
  margin-top: 10px;
}

.hideout__popup_progress {
  margin: 13px;
}


.hideout-fade-enter-active, .hideout-fade-leave-active {
  transition: all .5s;
}
.hideout-fade-enter, .hideout-fade-leave-to {
  transform: translateX(100%);
}


.instructions {
    .fadeContainer {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }

    .fadeOne-enter-active, .fadeOne-leave-active {
        transition: all .5s ease-out;
    }
    .fadeOne-enter, .fadeOne-leave-to {
        clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
        transform: translate(0%, 100%);
    }

    .fadeTwo-enter-active, .fadeTwo-leave-active {
        transition: all .5s ease-out;
    }
    .fadeTwo-enter, .fadeTwo-leave-to {
        clip-path: polygon(0% 100%, 100% 100%, 0% 100%, 0% 100%);
        transform: translate(0%, -100%);
    }
}

