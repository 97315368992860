@mixin base-type {
  font-family: 'Montserrat', sans-serif;
}
@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin image($w, $h) {
  img {
    width: $w;
    height: $h;
  }
}

@mixin button($border-radius: 63px) {
  border: 0px;
  background: transparent;
  text-transform: uppercase;
  transition: opacity 0.2s ease-out;
  line-height: 25px;
 
  &:hover {
    @include grow-effect-do();
  }
}

@mixin button-add-hover($foreground, $background) {
  transition: all 0.3s ease;
	&:hover {
    cursor: pointer;
}

}

@mixin fluid-property($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@mixin grow-effect() {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

@mixin grow-effect-do() {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@mixin flash-effect() {
  -webkit-animation: flash linear 1s infinite;
  animation: flash linear 1s infinite;
}

@mixin push-effect() {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

@mixin push-effect-do() {
  -webkit-animation-name: hvr-push;
  animation-name: hvr-push;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

@mixin buzz-effect-do() {
  -webkit-animation-name: hvr-icon-buzz-out;
  animation-name: hvr-icon-buzz-out;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}


@mixin pixel-button($background, $foreground) {
  padding: 1px 20px 1px 20px;
  color: $foreground;
  background-color: $background;
box-shadow:
/* Inner Background Color -- Same as inner */
  0   -6px 0  -2px $background,
  0    6px 0  -2px $background,
 
  0  -18px 0  -8px $background,
  0   18px 0  -8px $background,
 

/* Inner Border */
 -2px  0   0   0   $foreground,
  2px  0   0   0   $foreground,
 
  0  -10px 0  -2px $foreground,
  0   10px 0  -2px $foreground,
 
  0  -20px 0  -8px $foreground,
  0   20px 0  -8px $foreground,
 

/* Middle Border -- Accent */
 -4px  0   0   0   $foreground,
  4px  0   0   0   $foreground,
  -6px  0   0   0   $foreground,
  6px  0   0   0   $foreground,
  0   -8px 0   0px $foreground,
  0    8px 0   0px $foreground,
  
  0  -16px 0  -4px $foreground,
  0   16px 0  -4px $foreground,
 
  0  -28px 0 -12px $foreground,
  0   28px 0 -12px $foreground,

/* Outer Border */
 -6px  0   0   0   $foreground,
  6px  0   0   0   $foreground,
  0    0   0   4px $foreground,
  0   -6px 0   2px $foreground,
  0    6px 0   2px $foreground,
 
  0  -14px 0  -2px $foreground,
  0   14px 0  -2px $foreground,
 
  0  -24px 0  -8px $foreground,
  0   24px 0  -8px $foreground; 

  @include drop-filter();
}

@mixin drop-filter() {
  filter: drop-shadow(6px 8px 3px rgba(0,0,0,0.2));
  -webkit-filter: drop-shadow(6px 8px 3px rgba(0,0,0,0.2));
  -moz-filter: drop-shadow(6px 8px 3px rgba(0,0,0,0.2));
}

@mixin modal-dropshadow() {
  box-shadow: 10px 10px 26px 7px rgba(0,0,0,0.39);
  -webkit-box-shadow: 10px 10px 26px 7px rgba(0,0,0,0.39);
  -moz-box-shadow: 10px 10px 26px 7px rgba(0,0,0,0.39);
}
