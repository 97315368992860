.first-encounter {
  .gameplay-container {
    transform: translate(0px, 2000px);
    position: relative;
  }
    .alien-ship {
        display: block;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;

        max-width: 30vw;
        height: auto;
        margin: 0 auto;
    }
    .showAlienShip {
        opacity: 1;
    }
    .board-timer-grid {
        //display: grid;
        //grid-template-rows: 71vh 10vh;
        //grid-template-columns: 1fr;
        //grid-gap: 5vh;
        //max-width: 90%;

      .play_timer {
        flex: 0 0 35%; // Don't grow, don't shrink, stay at 35% width
        display: flex;
        //flex-direction: column;
        justify-content: flex-start;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 5px; // Rounded corners only on right side
        box-shadow: 5px 5px 8px 0px rgba(0,0,0,0.60);
        padding: 10px;
        margin-top: 5vh;

        .bar {
          height: 22px;
          background-color: rgba(51,51,51,0.9); // Green color for the timer bar
          border-radius: 11px; // Rounded edges for the bar
          transition: width 0.1s linear; // Smooth transition for the timer
          margin-left: 15px;
          margin-top: 4px;
        }

        .time-left {
          text-align: center;
          font-weight: bold;
          margin-top: 5px;
        }
      }

        @include fluid-property('max-width', 1100px, 1200px, 70%, 72%);
        margin: 0 auto;

        //.play_timer {
        //    height: 36px;
        //}

      .order-bonus {
        position: relative;
        z-index: 20;
        margin: 0 auto;
        top: -75vh;
        width: 30vw;
        img {
          width: 100%;
          height: auto;
        }

        h2 { margin: 0; }
      }
    }

  .grid {
    width: 100%;
    max-height: 80vh;
    margin: 0 auto;
    margin-top: -27px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 1.2rem;
    grid-row-gap: 1.2rem;

    .play-button {
      position: relative;

      &:before {
        content: "";
        display: block;
        height: 0;
        width: 0;
        padding-bottom: calc(1/1 * 100%);
      }

      span {
        display: none;
        position: absolute;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 5rem;
        z-index: 99999;
        width: 200%;
        left: -29%;
        top: 10px;
        padding: 5px;
        transition: all 0.2s ease;
        background-size: auto;
        height: 100%;
      }

       img {
          position: absolute;
          top: 0;
          width: 107%;
          height: auto;
          display: inline-block;
          padding-bottom: 0px;
          overflow: hidden;
          border: 0px;
          background: transparent;
          outline: none;

          -webkit-transition: 0.2s ease-in-out;
          transition: 0.2s ease-in-out;
          @include grow-effect;
        }

        @media (min-width: 1100px) {
          width: 85%;
        }
      }

      .hint-mode {
        @include push-effect();
        &:active {
          @include push-effect-do();
        }
      }

      .play-button:not(.play-disabled):not(.correct):not(.incorrect) {
        &:hover {
          cursor: pointer;
        }
        img {
          &:hover {
            @include grow-effect-do;
            cursor: pointer;
          }
        }

        &:not(.play-disabled):not(.incorrect) {
          &:active {
            @include push-effect-do;
          }
        }

        &.incorrect:not(.play-disabled) {

          @include buzz-effect-do;
        }

        &.incorrect,.play-disabled {
          cursor: default;
        }
      }
  }
}

