:root {
  --inner: white;
  --border: black;
  --accent: black;
}

nav {
  display: grid;
  &.main-screen {
    grid-template-columns: 1fr 2fr;
    .in-mission {
      color: #ffffff;
      background-color: maroon;
      margin-right: 15px;
      padding: 12px;
      margin-top:-3px;
      border-radius: 5px;
      span {
        font-weight: 900;
      }
    }

    .free-play {
      color: #ffffff;
      background-color: rgba(0, 94, 128, 0.5);
      padding: 12px;
      border-radius: 5px;
      span {
        font-weight: 900;
      }
      margin-right: 15px;
      cursor: default;
      pointer-events: none;
    }
  }
  &.in-game {
    grid-template-columns: 1.2fr 2.4fr 1.6fr 2.4fr 3fr;

    @media (max-width: 1024px) {
      grid-template-columns: 2fr 2.4fr 1.6fr 2.4fr 3fr;
    }

    grid-column-gap: 1rem;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));


    .trial, .round {
      background-color: #000;
      border: 3px solid #fff;
      color: #fff;
      border-radius: 5px;

      padding: 3px 0px 3px 8px;
      display: flex;
      align-items: center;
      position: relative;

      span {
        position: relative;
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: 6px;
        background-color: #b3b3b3;
        &.active {
          background-color: #ffffff;
          border: 1px solid #ffffff;
        }

        &.correct {
          &::before {
            content: "";
            display: block;
            width: 24px; /* adjust as needed */
            height: 24px;
            background: url($cdn_url + 'trial-check.svg') no-repeat;
            background-size: contain;
            position: absolute;
            top: 2px;
            left: -1px;

          }
        }

        &.incorrect {
          &::before {
            content: "";
            display: block;
            width: 24px; /* adjust as needed */
            height: 24px;
            background: url($cdn_url + 'trial-x.svg') no-repeat;
            background-size: contain;
            position: absolute;
            top: -2px;
            left: -2px;

          }
        }

      }

      h4 {
        display: inline-block;
        font-size: 16px;
        color: #fff;
        font-weight: 700;
        margin: 0px;
        margin-top: 12px;
        padding: 0px 10px 0px 0px;
        line-height: 20px;
        height: 100%;
        right: 0px;
          border: 0;

      }
    }
  }
  padding: 15px 50px 15px 50px;
  @include base-type;
  h4 {
    margin: 0px;
    @include fluid-property(font-size, 360px, 1200px, 12px, 20px);
    color: #4A4A4A;
    text-align: left;
  }
    .hint-text {
        h4 {
            color: #ffffff;
            @include fluid-property(font-size, 360px, 1200px, 12px, 17px);
            margin-top: -4px;
        }
    }
  // .meta-navigation, nav {
    .meta-navigation {
      justify-self: end;
    }

    button {

      position: relative;

      @include base-type;
      @include button;
      @include button-add-hover($dark-color, $light-color);
      &.level {
        border-radius: 5px;
        &:hover {
          pointer-events: none;
        }

      }
      &.games, &.tickets, &.level {
        cursor: default;
        pointer-events: none;
        font-weight: 400;
        margin-right: 10px;
        @include fluid-property(font-size, 360px, 1200px, 14px, 16px);
        span span {
          font-weight: 600;
          padding-right: 8px;
        }
        color: $dark-color;
      }

      &.no-cursor {
        :hover {
          cursor: none;
          pointer-events: none;
          transform: scale(1) !important;
        }
      }
      &.logout {
        @include fluid-property(font-size, 360px, 1200px, 14px, 16px);

        text-align: center;
      }

      &.mute {
        background: url($cdn_url + 'icon-audio.svg') no-repeat;
        // make background image white instead of black
        filter: invert(1);
        background-size: contain;
        text-indent: -9999px;
        overflow: hidden;
        width: 30px;
        height: 30px;
        margin-right: 0.8rem;

        &.isMuted {
            background: url($cdn_url + 'icon-no-audio.svg') no-repeat;
            background-size: contain;
        }

      }
    }


    .tickets {
      font-size: 1.5em !important;
      border-radius:0px;
      background: url($cdn_url + 'ticket.webp') no-repeat;
      background-size: 100%;
      background-size: contain;
      text-align: center;
      min-width: 108px;
      min-height: 52px;

      @include noselect;

      &:hover {
        cursor: default;
        opacity: 1;
      }
      &:focus {
        outline: none;
      }
      span {
        padding: 0px !important;
        font-family: "NunitoBlack", sans-serif;
        color: #ffffff;
        display: inline-block;
        text-align: center;
      }
    }
}

.task-hint.mission-mode {
display: grid;
  grid-template-columns: 1fr 8fr;
  grid-gap: 2rem;
  position: absolute;
  .task-hint__arrow svg {
    width: 120%;
    height: auto;
  }
  top: 50vh;
  left: 50vw;
  margin-left: -20vw;  /* Negative half of your width */
  z-index: 5000;
  background-color: #ffffff;
  color: #000000;
  padding: 25px 25px;
  min-width: 19vw;
  max-width: 50vw;
  border-radius: 5px;
  @include modal-dropshadow();
  h3 {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  .task-hint__text {
    font-size: 1.3em;
    font-weight: 400;

    strong {
      font-weight: 700;
      text-transform: uppercase;
    }

    margin: 0;
  }

}

