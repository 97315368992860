@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@import './components/variables';
@import './components/mixins';

:root {
  --base-url: $cdn_url + '';
  touch-action: pan-x pan-y;
  height: 100%;
  zoom: unset;
}

/* nunito-regular - latin-ext_latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url($cdn_url + 'fonts/nunito-v16-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url($cdn_url + 'fonts/nunito-v16-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

/* nunito-900 - latin-ext_latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  src: local(''),
  url($cdn_url + 'fonts/nunito-v16-latin-ext_latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url($cdn_url + 'fonts/nunito-v16-latin-ext_latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

body {
  background: url($cdn_url + 'arcade-background.webp');
  background-size: 300px 266px;
  
  overflow: hidden;
  @include base-type;

  /* Make all elements unselectable */

  * {
    font-family: "Nunito", sans-serif; font-weight: 400;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    cursor: default;
  }

  /* Override cursor style for buttons and other interactive elements */
  a, button, input[type="button"], input[type="submit"] {
    cursor: pointer; /* Change the cursor back to the pointer for buttons and links */
    * {
      cursor: pointer; /* Change the cursor back to the pointer for buttons and links */
    }
  }

  button:disabled {
    cursor: none;
  }

  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Nunito", sans-serif; font-weight: 900;
  }

  transition: background-color 0.8s ease-out;
}

.q-layout__section--marginal {
  background: none transparent !important;
}

h1 {
  margin-top: 0px;
  margin-bottom: 25px;
  line-height: 2rem;
}

@import './components/nav';
@import './games/games';
@import './components/transitions';
@import './components/login';

.error {
  display: block;
  width: 100%;
  padding: 15px;
  color: #721c24;
  background-color: #f8d7da;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity 0.8s, margin-top 0.4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  margin-top: 3rem;
}

.paper-item {
  transition: transform 0.5s ease;
}

.paper-enter-active, .paper-leave-active {
  transition: all 0.5s ease;
}

.paper-leave-to {
  transform: translateX(-100%) rotate(-30deg);
  opacity: 0;
}

