
.shapeshooter__container {
  width: 100%;
  height: 100vh;
}

.shape-shooter-card {
  overflow: hidden !important;
  .answer-modal {
    overflow: hidden !important;
  }
}

.shapeAnswerRow {
  display: grid;
  grid-template-columns: 1fr 9fr;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  width: 100%;
  padding: 0.3rem;
  vertical-align: top;
  border-radius: 3px;
  -webkit-transition: background-color 0.5s ease-out;
  -ms-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;

  .col {
    //vertically center content
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &:not(:last-child) {
    padding-bottom: 1px;
  }

}

.shape-shooter-card.q-card {
  @include modal-dropshadow();

  h1 {
    @include fluid-property('font-size', 320px, 1200px, 16px, 32px);
    text-align: center;
    font-weight: 700;
    color: #000;
    margin: 25px 0 25px 0;
  }

  p {
    @include fluid-property('font-size', 320px, 1200px, 14px, 18px);
    color: #000;
    margin-bottom: 25px;
  }
}

.shapeshooter__paddle {
  @include responsive-bg('games/ShapeShooter/images/', 'blue-banner.webp');
  background-repeat: no-repeat;
  width: 200px;
  height: 420px;
  bottom: 236px;
  opacity: 0;
  z-index: 5;
  background-size: contain;
  display: flex;
  justify-content: center;
  position: absolute;

  padding: 15px;
  .shapeshooter__symbol {
    &:hover {
      opacity: 1;
      transform: scale(1) !important;
      cursor: default !important;
    }
  }
}
.answer_hint_text {
  font-weight: 700;
  font-size: 1.2em;
  text-align: right;
  height: 100%;
  margin-right: 1.5rem;
  text-transform: uppercase;
  // vertical centering
  display: flex;
  align-items: center;
}
.category-answer-row {
  background-color: rgba(255, 255,255,1);

  &.correct {
    background-color: rgba(173, 255, 47, 0.75);
  }

  &.incorrect {
    background-color: rgba(255, 0, 0, 0.75);
  }

  border-radius: 5px;
  display: grid;
  grid-auto-columns: minmax(40px, 1fr);
    grid-auto-flow: column;
  .answer-container {

    display: flex;
    align-items: center;
    justify-content: center;
    .answer-symbol {
      align-items: center;
      justify-content: center;
    }

    &.answer-color {
      border: 0;
        width: 80%;
        height: auto;
        border-radius: 100%;
      margin: 1rem 0 1rem 1rem;
      align-items: center;
      justify-content: center;

    }

  }
}
.shapeshooter__symbol {
  background-image: url($cdn_url + 'games/ShapeShooter/images/shapes/apple.webp');
  background-repeat: no-repeat;
  width: 120px;
  height: 120px;
  background-size: contain;
  border: 0;
  background-color: transparent;

  margin-top: 55px;

  transition: all 0.2s ease-in-out;


  &:not(.answer_hint) {
    &:hover {
      transform: scale(1.3);
      cursor: pointer;
    }

  }

  &.answer_hint {
    background-position: center;
    padding: 10px;
    opacity: 0.5;
  }
}

.answer_hint, .answer-symbol {
  margin-top: 0;
}

.shapeshooter__sky {
  @include responsive-bg('games/ShapeShooter/images/', 'background.webp');
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 536px;
  z-index: 0;
  background-size: cover;
}

.shapeshooter__backmountains {
  @include responsive-bg('games/ShapeShooter/images/', 'back-mountain.webp');
  background-repeat: repeat-x;
  width: 100%;
  height: 410px;
  position: absolute;
  bottom: 236px;
  z-index: 2;
  background-size: cover;
}

.shapeshooter__mountains {
  @include responsive-bg('games/ShapeShooter/images/', 'front-mountain.webp');
  background-repeat: repeat-x;
  width: 100%;
  height: 410px;
  position: absolute;
  bottom: 236px;
  z-index: 2;
  background-size: cover;
}

.shapeshooter__bushes {
  @include responsive-bg('games/ShapeShooter/images/', 'front-grass.webp');
  background-repeat: repeat-x;
  width: 100%;
  height: 301px;
  position: absolute;
  bottom: 10vh;
  z-index: 4;
}

.shapeshooter__wall {
  @include responsive-bg('games/ShapeShooter/images/', 'stones.webp');
  background-repeat: repeat-x;
  width: 100%;
  height: 356px;
  position: absolute;
  bottom: -100px;
  z-index: 6;
  background-size: cover;
}

.answer-container {
  background-position: center;
  aspect-ratio: 1/1;
  max-width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

}



.group-hints {
  position: absolute;

  top: 0px;
  left: 15vw;
  z-index: 5000;
  background-color: #ffffff;
  color: #000000;
  padding: 10px 15px 10px 15px;
  min-width: 19vw;
  border-radius: 5px;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.3em;
  strong {
    font-weight: 700;
    text-transform: uppercase;
  }
  @include modal-dropshadow();
}
.task-hint {
  display: grid;

  grid-template-columns: 1fr 8fr;
  align-items: center;
  position: absolute;

  top: 0px;
  left: 15vw;
  z-index: 5000;
  background-color: #ffffff;
  color: #000000;
  padding: 10px 15px 10px 5px;
  min-width: 19vw;
  border-radius: 5px;

  @include modal-dropshadow();

  .task-hint__text {
    font-size: 1.3em;
    font-weight: 400;

    strong {
      font-weight: 700;
      text-transform: uppercase;
    }

    margin: 0;
  }

}

.background-grey {
  background-color: #999999;
}

.background-orange {
  background-color: #E69F00;
}

.background-green {
  background-color: #009E73;
}

.background-yellow {
  background-color: #F0E442;
}

.background-blue {
  background-color: #0072B2;
}

.background-red {
  background-color: #d61b1b;
}

.background-purple {
  background-color: #CC79A7;
}


