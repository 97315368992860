.black-text {
  color: black !important;
}

.white-text {
  color: white !important;
}

.wordwheel__white_background {
  background: white;
  text-transform: uppercase;
}

.wordwheel__incorrect_background {
  @include responsive-bg-norepeat('games/WordWheel/images/', 'background-incorrect.webp');
}

.wordwheel__correct_background {
  @include responsive-bg-norepeat('games/WordWheel/images/', 'background-correct.webp');
}

.wordwheel__green_background {
  background: green;
  text-transform: uppercase;
}

.wordwheel__red_background {
  background: red;
  text-transform: uppercase;
}

#main {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

$pi: 3.14159265358979;

$spinner-size: 55vw;
$spinner-radius: $spinner-size / 2px;
$spinner-circumference: $pi * $spinner-size;
$spinner-slices: 12;

$slice-size: $spinner-circumference / $spinner-slices;
$slice-colors: #a94fca, #EE4266, #FFD23F, #3BCEAC, #2765d4, #FF715B, #a94fca, #EE4266, #FFD23F, #3BCEAC, #2765d4, #FF715B;
.reaction-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 109vw;
  opacity: 0.4;
  background-size: cover;
  -webkit-transition: background 1.5s linear;
  -moz-transition: background 1.5s linear;
  -o-transition: background 1.5s linear;
  -ms-transition: background 1.5s linear;
  transition: background 1.5s linear;
}

.wordwheel__arrow-pointer {
  width: calc(#{$spinner-size} * 0.6);
  height: 120px;
  background: white;
  position: absolute;
  right: calc(-50px - #{$spinner-size} * 0.6);
  top: 43%;
  @include responsive-bg('games/WordWheel/images/', 'pointer.webp');
  box-shadow: 0px 0px 0px 0.1vw black, 0px 0px 0px 0.1vw black;

  .wordwheel__arrow-title {
    color: black;
    font-size: 40px;
    margin-right: 20px;
    text-align: right;
    margin-block-end: 0px;
    padding-top: 6px;
  }

  .wordwheel__arrow-desc {
    color: black;
    font-size: 18px;
    text-align: right;
    margin-right: 20px;
  }

  .wordwheel__arrow-desc-span {
    color: blueviolet;
    font-size: 25px;
  }

}

.wordwheel__arrow-pointer--incorrect {
  @extend .wordwheel__arrow-pointer;
  background: red;
}

.wordwheel__arrow-pointer--correct {
  @extend .wordwheel__arrow-pointer;
  background: green;
}

.wordwheel__arrow-left-point {
  width: 86px;
  height: 86px;
  background: white;
  position: absolute;
  right: -92px;
  top: calc(43% + 17px);
  transform: rotate(-45deg);
  border-right: 0px;
  border-bottom: 0px;
  @include responsive-bg-repeat('games/WordWheel/images/', 'pointer.webp');
  -webkit-box-shadow: -38px 8px 33px -23px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: -38px 8px 33px -23px rgba(0, 0, 0, 0.51);
  box-shadow: -38px 8px 33px -23px rgba(0, 0, 0, 0.51);
}

.wordwheel__arrow-left-point--incorrect {
  @extend .wordwheel__arrow-left-point;
  background: red;
}

.wordwheel__arrow-left-point--correct {
  @extend .wordwheel__arrow-left-point;
  background: green;
}

.wordwheel__spinner {
  --spin-time: 1000;
  position: relative;
  left: -40vw;
  width: $spinner-size;
  height: $spinner-size;
  padding-bottom: 40%;
  margin: 2rem 2rem;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
  }

  &-center {
    position: absolute;
    width: 60%;
    height: 60%;
    left: 20%;
    top: 20%;
    background-color: #4a2222;
    border: 15px solid #080808;
    border-radius: 400px;
    @include responsive-bg-repeat('games/WordWheel/images/', 'wheel-background-red.webp');

  }

  &-list {
    background-color: white;
    transition: transform calc(var(--spin-time) * 1ms) ease-out;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;

    margin: 0px;
    padding: 0px;
    box-shadow: 0px 0px 0px 0.8vw black, 0px 0px 0px 0.9vw black;

    &:after {
      content: '';
      display: block;
      width: 1.7vw;
      height: 1.7vw;
      background-color: #3c3434;
      position: absolute;
      border-radius: 50%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &-slice {
    position: absolute;
    display: flex;
    top: 50%;
    left: 50%;
    transform-origin: left;
    list-style: none;
    height: $slice-size;
    width: $spinner-size/2;
    justify-content: flex-end;
    align-items: center;
    z-index: -1;

    &-text {
      z-index: 1;
      text-align: right;
      padding: 0.5em 0.5em 0.5em 2.2em;
      max-width: 100%;
      user-select: none;
      color: darkgray;
      font-size: calc(#{$spinner-size} / 8);
      font-family: Calibri, sans-serif;
      backface-visibility: visible;
      font-weight: 600;
      transform: translateY(-10%) rotate(-10deg);
    }
  }

  &-lever {
    top: 0;
    bottom: 0;
    margin: auto;
    left: calc(100% + 0.4vw);
    width: 20vw;
    height: 20vw;
    text-align: center;

    &-button {
      position: absolute;
      border: 0;
      @include responsive-bg-repeat('games/WordWheel/images/', 'red_push_btn.webp');
      padding: 0;
      width: 135px;
      height: 106px;
      border-radius: 100%;
      cursor: pointer;
      top: calc(54% - #{1.8/2 * 1vw});
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1;

      &:active {
        @include responsive-bg-repeat('games/WordWheel/images/', 'red_push_btn-pushed_in.webp');
      }

      .is-spinning & {
        animation: moveLever 3000ms ease-in-out;
      }

      @at-root {
        @keyframes moveLever {
          0%, 100% {
            transform: translateY(0%)
          }
          10% {
            transform: translateY(240%);
          }
        }
      }
    }
  }
}

@for $i from 1 through $spinner-slices {
  .wordwheel__spinner-slice:nth-of-type(#{$i}) {
    transform: translateY(-50%) rotate(30deg * $i);
  }
}

.word-wheel {
  .gameplay-container {
    transform: translate(-1500px, 0px);

    .wheel-container {
      position: absolute;
      right: 20vw;
      top: 0;
      margin-top: -35px;
    }
    .wheel-wrapper {
      aspect-ratio: 1 / 1;
      width: $spinner-size;
      margin-top: -15vh;
      position: relative;
      left: -25vw;

      top: -1%;
      transform: rotate(60deg);
    }

    .wheel-pointer {
      width: 60px;
      height: 60px;
      border-radius: 100%;
      background: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      line-height: 60px;
      z-index: 10;
      cursor: pointer;
    }

    .wheel-bg {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      overflow: hidden;
      background: #fff;
    }

    .wheel-bg::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      aspect-ratio: 1 / 1;
      width: 65%;
      border-radius: 50%;
      border: 10px solid #000000;
      background-color: var(--wheel-color);
      z-index: 1;
    }

    .wheel-bg.freeze {
      transition: none;
      background: red;
    }

    .letter-list {
      width: 100%;
      height: 100%;
      position: relative;
      text-align: center;
    }

    .letter-container-wrapper {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 50%;
      height: 50%;
    }

    .letter-container {
      width: 100%;
      height: 100%;
      transform-origin: bottom;
    }

    .letter-container .letter {
      font-weight: bold;
      transform: rotate(-90deg);
      transition: filter 1s cubic-bezier(0.8, 0, 0.3, 1);
      font-size: clamp(2em, 6vw, 6em);
      padding: 0.8em;
      opacity: 0.6;
    }

    @media (max-width: 576px) {
      .letter-container .letter {
        font-size: 2.5em;
        padding: 0em;
      }
    }

    @media (min-width: 576px) and (max-width: 768px) {
      .letter-container .letter {
        font-size: clamp(1.5em, 6vw, 4em);
        padding: 0.1em;
      }
    }

    @media (min-width: 768px) and (max-width: 992px) {
      .letter-container .letter {
        font-size: clamp(2em, 8vw, 3em);
        padding: 0.2em;
      }
    }

    @media (min-width: 992px) and (max-width: 1200px) {
      .letter-container .letter {
        font-size: clamp(1.5em, 10vw, 4.5em);
        padding: 0.2em;
      }
    }

    @media (min-width: 1201px) and (max-width: 1249px) {
      .letter-container .letter {
        font-size: clamp(1.5em, 10vw, 5em);
        padding: 0.2em;
      }
    }

    @media (min-width: 1250px) {
      .letter-container .letter {
        font-size: 6em;
        padding: 0.1em;
        opacity: 0.6;
      }
    }

    .playbutton {
      position: absolute;
      bottom: 15px;
      left: 50%;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 0;

    }

    .pointer {
      position: absolute;
      left: 50%;
      top: 50%;
    }

    /********* end */
  }

  .current-letter {
    opacity: 1 !important;
  }

  .q-field__inner {
    input {
      padding: 15px 10px;
      font-size: 1.3rem;
      font-weight: 600;
    }
  }

  .bonus-prompt {
    min-width: 65vw;
    min-height: 33vh;

    h3 {
      margin: 0 0 1.5rem 0;
    }

    .bonusInput {
      padding: 15px;
    }
  }
}