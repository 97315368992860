.master-thief {
    position: static;

  .gameplay-container {
    transform: translate(0px, 2000px);


  }
  .safe, .grid {
    @include fluid-property('max-width', 1100px, 1200px, 80%, 59%);
    aspect-ratio: 3 / 2;
  }
  .safe {
    @include absolute-centered;
    aspect-ratio: 3 / 2;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    box-shadow: create-box-shadow(2);

    margin-top: 10px;
    padding: 1.8em 1.8em 0;
    z-index: 5;
    .gems {
      width: 80%;
      margin: 0 auto;
      position: absolute;
      bottom: 5vh;
      span {
        display: block;

        .gem {
          display: inline-block;
          width: 33%;
          height: auto;
          transition: all 0.2s ease;
        }
        .gemAnimateOut {
          transform: translateX(-10vw), translateY(-100%);
        }
      }
    }
  }
  .grid {
    @include absolute-centered;
    transform: translate(-3000px, 0px);
    z-index: 10;

    display: grid;
    margin-top: 10px;
    grid-template-columns: repeat(7, 12%);

    @include fluid-property('grid-gap', 1100px, 1200px, 1em, 0.8em);

    padding: 1em;

    .play-button {
      min-height: 0;
      position: relative;

      &.show.hint-mode {
        img {
          &:hover {
            cursor: pointer;
          }
          will-change: transform, opacity;
          animation: pulse var(--display-interval) infinite;

          @keyframes pulse {
            0%, 100% {
              transform: scale(1);
              opacity: 1;
            }
            50% {
              transform: scale(1.05);
              opacity: 0.8;
            }
          }
        }
      }

      &.correct.active {
        background-color: pink;
      }

      &:before {
        content: "";
        display: block;
        height: 0;
        width: 0;
        padding-bottom: calc(1/1 * 100%);
      }

      span {
        display: none;
        position: absolute;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 5rem;
        z-index: 99999;
        width: 200%;
        left: -29%;
        top: 10px;
        padding: 5px;
        transition: all 0.2s ease;
        background-size: auto;
        height: 100%;
      }

       img {
          position: absolute;
          top: 0;
          width: 100%;
          height: auto;
          display: inline-block;
          padding-bottom: 0px;
          overflow: hidden;
          border: 0px;
          background: transparent;
          outline: none;

        }

        &.correct img, &.incorrect img {
          width: 100%;
        }
      }

      .hint-mode {
        @include push-effect();
        &:active {
          @include push-effect-do();
        }
      }

      .play-button:not(.play-disabled) {
        &.show:hover {
          cursor: pointer;
        }
        img {
          &:hover {
            @include grow-effect-do;
            cursor: pointer;
          }

          &:not(.play-disabled):not(.incorrect) {
            &:active {
              @include push-effect-do;
            }
          }

          &.incorrect:not(.play-disabled) {
            @include buzz-effect-do;
          }

          &.incorrect,.play-disabled {
            cursor: default;
          }
        }
      }
  }

    .play_timer {
        @include absolute-centered;
        top: 83vh;
        z-index: 6666;
    }
}
