@mixin absolute-centered {
    position:absolute;
    margin-left:auto;
    margin-right:auto;
    left: 0;
    right: 0;
}

@mixin responsive-bg($assetPath, $filename) {
  background-image: url($cdn_url + $assetPath + $filename);

  @media (min-width: 768px) {
    background-image: url($cdn_url + $assetPath + 'small/' + $filename);
  }

  @media (min-width: 1001px) {
    background-image: url($cdn_url + $assetPath + 'medium/' + $filename);
  }

  @media (min-width: 1801px) {
    background-image: url($cdn_url + $assetPath + 'large/' + $filename);
  }
}

@mixin responsive-bg-norepeat($assetPath, $filename) {
  background: url($cdn_url + $assetPath + $filename) no-repeat;

  @media (min-width: 768px) {
    background: url($cdn_url + $assetPath + 'small/' + $filename) no-repeat;
  }

  @media (min-width: 1001px) {
    background: url($cdn_url + $assetPath + 'medium/' + $filename) no-repeat;
  }

  @media (min-width: 1801px) {
    background: url($cdn_url + $assetPath + 'large/' + $filename) no-repeat;
  }
}

@mixin responsive-bg-repeat($assetPath, $filename) {
  background: url($cdn_url + $assetPath + $filename) no-repeat;

  @media (min-width: 768px) {
    background: url($cdn_url + $assetPath + 'small/' + $filename) no-repeat;
  }

  @media (min-width: 1001px) {
    background: url($cdn_url + $assetPath + 'medium/' + $filename) no-repeat;
  }

  @media (min-width: 1801px) {
    background: url($cdn_url + $assetPath + 'large/' + $filename) no-repeat;
  }
}

@function create-box-shadow($n)
{
    $box-shadow: '';
    @for $i from 1 through $n {
        $shadows:   $i + 'px ' + $i +'px 0px rgba(0,0,0,0.1)' ;
        $box-shadow: $box-shadow + $shadows + ' ,';
    }
    @return str_slice(unquote($box-shadow), 0, str_length($box-shadow)-1) ;
}

.blur-content {
  filter: blur(3px);
  pointer-events: none;
}

.level {
  background-color: #000;
  border: 3px solid #fff;
  h4 {
    display: inline-block;
    color: #fff !important;
  }
}
.hints {
  background-color: #000;
  border: 3px solid #fff;
  border-radius: 5px;

  display: grid;
  grid-template-columns: 5fr 1fr;
  align-items: center;
  color: #fff;
  &.colored {
  .the-hints {
      padding: 8px 0px 4px 8px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 0.5rem;
    .hints-color:not(.colorHintHasText) {
      .hint-color:not(:last-child) {
        margin-right: 3px;
      }
    }

    .hints-color.colorHintHasText {
      margin-top: 4px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(22px, auto));
      grid-gap: 3px;
    }
    .hint-color {

      display: inline-block;
      width: 22px;
      height: 22px;
      border-radius: 50%;
        &.yellow {
          background-color: #F0E442;
        }

        &.green {
          background-color: #009E73;
        }

        &.red {
          background-color: #D61B1B;
        }

        &.grey {
          background-color: #999999;
        }

        &.orange {
          background-color: #E69F00;
        }

        &.blue {
          background-color: #0072B2;
        }

        &.purple {
          background-color: #CC79A7;
        }
      }
    }
  }

  .the-count {
    font-size: 20px;
    font-weight: 700;
    text-align: right;
    padding-right: 15px;
  }
}

.play_timer {
  width: 50vw;
  max-width: 50%;
  margin: 0 auto;

  border: 2px solid #fff;
  padding: 4px 8px 4px 4px;
  min-height: 25px;

  .bar {
    transition: width 0.4s ease-out;
    background-color: #fff;
    min-height: 23px;
  }
  color: #000;
  font-size: 25px;
}
.game-play {
  width: 100vw;
  .play {
    position: relative;
  }
  .actions {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 22px;
    .button {
      @include button();
      @include button-add-hover(#fff, #333);
      &:first-child {
        margin-right: 8px;
      }
    }
  }
}

.modal {
  position: absolute;
  z-index: 10000;
  pointer-events: auto; /* Allows clicks on the modal */
  top: 0;

  background-color: rgba(0,0,0,0.5);

  &.modal-message {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Nunito", sans-serif;
    .content {
      position: relative;
      padding: 25px;
      border-radius: 5px;

      @include modal-dropshadow();
      h1 {
        @include fluid-property('font-size', 320px, 1200px, 1rem, 2.2rem);
        font-weight: 700;
      }

      p, .body {
        @include fluid-property('font-size', 320px, 1200px, 0.8rem, 1.2rem);
      }
      width: 75%;
      max-width: 580px;
      max-height: 55vh;

      background-color: #fff;
      color: #000;
      .fadeContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .modal-action {

        width: 100%;
        display: flex;
        justify-content: center;

        .modal_action_button {
          @include base-type;
          @include button;
          @include noselect;
          @include button-add-hover($light-color, $dark-color);
          background-color: $dark-color;
          color: $light-color;
          border: 6px solid $light-color;
          padding: 12px 20px;
          border-radius: 15px;
          &:focus { outline: none; }
          font-family: "Nunito", sans-serif;
          font-size: 1.1rem;
          font-weight: 600;
          letter-spacing: 0.04em;
          display: inline-block;
          margin: 50px auto 0;
        }
      }
    }
  }
}

#overlay {

  position: fixed; /* Fixed position to cover the whole screen */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 9999; /* Overlay level */
  pointer-events: auto; /* Allows clicks on the overlay only */
}

button {
  @extend .button;
  font-size: 1.1rem;
  span.pixel {
    @include pixel-button($light-color, $dark-color);
  }

  span.pixel-inverse {
    @include pixel-button($dark-color, $light-color);
  }

  span.pixel-mission-mode {
    @include pixel-button(maroon, $light-color);
  }
}

.tada {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
}
@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}
