@mixin indicator($color) {
  box-shadow: inset 0px 0px 0px 8px $color;
  box-sizing: border-box;
  background: repeating-linear-gradient(
      45deg,
      $color,
      rgba(0,0,0,0) 5px
  );
}

.scout-trainer {
  .gameplay-container {
    transform: translate(0px, -2000px);

    // show indicator only when we're in play mode
    &.play-mode {
      .play-button {

        .army {
          transition: all 0.2s ease;
          &:not(.noHoverEffect):hover {
            @include indicator(#000000);
            cursor: pointer;
          }
        }
      }
    }

    .order-bonus {
      position: absolute;
      z-index: 20;
      margin: 0 auto;
      top: -15vh;
      // slant element slightly to the right
        transform: rotate(-10deg);
      // center horizontally

        right: 10vw;

      width: 15vw;
      img {
        width: 100%;
        height: auto;
      }

      h2 { margin: 0; }
    }
  }

  .grid {
    //max-width: 90%;

    //@include fluid-property('max-width', 1100px, 1200px, 125vh, 125vh);
    max-height: 80vh;
    margin: 10px auto 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-gap: 0;

    .play-button {
      position: relative;
      @include responsive-bg-norepeat('games/ScoutTrainer/images/', 'background.webp');
      &::before {
        content: '';
        width: 0;
        padding-bottom: 100%;
        grid-row: 1 / 1;
        grid-column: 1 / 1;
      }

      > *:first-child {
        grid-row: 1 / 1;
        grid-column: 1 / 1;
      }

      aspect-ratio: 1 / 1;

      .army {

        transition: all 0.2s ease;
        height: 100%;
        position: relative;
        z-index: 5;
        display: grid;
        grid-template-columns: repeat( 3, minmax(1px, 1fr) );
        grid-auto-rows: 1fr;
        padding: 18px;
        grid-column-gap: 15px;
        grid-row-gap: 5px;
        .orc {
          opacity: 0;
          transition: top 0.8s ease-out 0.5s, opacity 1s ease 0.3s, transform ease 0.5s;
          position: relative;

          &.shown {
            top: 0;
            opacity: 1;
          }

          &.faded {
            top: 0;
            opacity: 0;
          }

          &.dead {
            top: 0;
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
      }

      &.incorrect {
        .army, .army:hover {
          @include indicator(#b01a1a);
        }
      }
      .orc {
        opacity: 0;
        transition: top 0.8s ease-out 0.5s, opacity 1s ease 0.3s, transform ease 1s;
      }

      &:not(.hint-mode) {
        .orc {
          opacity: 0;
        }
      }

      span {
        display: none;
      }
      background-size: cover;
      &.tile1:before {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include responsive-bg-norepeat('games/ScoutTrainer/images/', 'tile-overlay1.png');
        filter: brightness(1.2) contrast(1.5); // Adjust this value to make it darker or lighter
      }

      &.tile2:before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include responsive-bg-norepeat('games/ScoutTrainer/images/', 'tile-overlay2.png');
        filter: brightness(0.9); // Adjust this value to make it darker or lighter
      }

      &.tile3:before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include responsive-bg-norepeat('games/ScoutTrainer/images/', 'tile-overlay3.png');
        filter: brightness(0); // Adjust this value to make it darker or lighter
      }

       img {
          max-width: 100%;
          height: auto;
          display: inline-block;
          padding-bottom: 0px;
          overflow: hidden;
          border: 0px;
          background: transparent;
          outline: none;

          -webkit-transition: 0.2s ease-in-out;
          transition: 0.2s ease-in-out;
          @include grow-effect;
        }
      }

      .hint-mode {
        // horizontally and vertically center child element
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        aspect-ratio: 1 / 1;
        @include push-effect();
        &:active {
          @include push-effect-do();
        }
      }

      .play-button:not(.play-disabled):not(.correct):not(.incorrect) {
        &:hover {
          cursor: pointer;
        }
        img {
          &:hover {
            @include grow-effect-do;
            cursor: pointer;
          }

          &:not(.play-disabled):not(.incorrect) {
            &:active {
              @include push-effect-do;
            }
          }

          &.incorrect:not(.play-disabled) {
            @include buzz-effect-do;
          }

          &.incorrect,.play-disabled {
            cursor: default;
          }
        }
      }
  }

  .feedback-and-timer {
    display: flex;
    justify-content: space-between;
    align-items: stretch; // Changed to stretch to make both elements the same height
    width: 100%;
    padding: 0 20px; // Added padding on both sides for consistency
    box-sizing: border-box;
  }

  .gameplay-feedback {
    flex: 1; // Allow it to grow and shrink
    position: relative;
    z-index: 50;
    max-height: 100px;
    overflow: hidden;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px 0 0 5px; // Rounded corners only on left side
    box-shadow: -5px 5px 8px 0px rgba(0,0,0,0.60);

    h4.scout-instructions {
      padding: 15px;
      margin: 0;
    }
  }

  .play_timer {
    flex: 0 0 35%; // Don't grow, don't shrink, stay at 35% width
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px; // Rounded corners only on right side
    box-shadow: 5px 5px 8px 0px rgba(0,0,0,0.60);
    padding: 10px;

    .bar {
      height: 22px;
      background-color: rgba(51,51,51,0.9); // Green color for the timer bar
      border-radius: 11px; // Rounded edges for the bar
      transition: width 0.1s linear; // Smooth transition for the timer
    }

    .time-left {
      text-align: center;
      font-weight: bold;
      margin-top: 5px;
    }
  }

  // Responsive design for smaller screens
  @media (max-width: 768px) {
    .feedback-and-timer {
      flex-direction: column;
      align-items: stretch;
    }

    .gameplay-feedback,
    .play_timer {
      width: 100%;
      max-width: 100%;
      border-radius: 5px;
      margin-bottom: 10px;
    }
  }

  .play_timer {
    // make this flex so that the timer bar and the clock image share the same line
    display: grid;
    grid-template-columns: 1fr 10fr;
    // align vertical center
    align-items: center;
    // align horizontal center




  }

  // For future animations
  @keyframes slideIn {
    from { transform: translateX(-100%); }
    to { transform: translateX(0); }
  }

  @keyframes slideOut {
    from { transform: translateX(0); }
    to { transform: translateX(100%); }
  }

  .slide-in {
    animation: slideIn 0.5s forwards;
  }

  .slide-out {
    animation: slideOut 0.5s forwards;
  }

  .disabled, .disabled *, [disabled], [disabled] * {
    cursor: default !important;
  }
}
