.progress {
  margin: 0 auto;
  width: 50vw;
  height: 50px;
  border: 3px solid #000000;
  padding: 4px;
  .incrementor {
    width: 0%;
    height: 100%;
    background-color: #999999;
    transition: width 0.5s ease-out;
  }
}
