.short-circuit {
  .gameplay-container {
    transform: translate(0px, 2000px);
    background-position-x: 50% !important;
  }

  .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000;
    top: 0;

    .modal.modal-short-circuit {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      @include responsive-bg('games/ShortCircuit/images/', 'modal-background.webp');
      width: 60%;
      height: 70%;
      margin: auto;
      top: auto;

      @include modal-dropshadow();

      .modal-body {
        width: 90%;

        .numbers {
          position: relative;
          // center horizontally
          margin: 0 auto;
          overflow: visible;
          border-radius: 15px;
          height: 210px;
          box-shadow: inset 6px 6px 12px #444,
          inset -6px -6px 36px #666;
          color: #444;

          h1 {
            font-size: 4.2rem;
            line-height: 100%;
            padding-bottom: 10px;

            &.correct {
              color: green;
            }

            &.incorrect {
              color: red;
            }

            div.sequence-numbers {
              font-family: 'Nunito', serif;
              font-style: normal;
              display: inline-block;
              line-height: 100%;
              font-weight: 900;
            }


            span.comma-separator {
              padding: 10px;
            }
          }

          .sequence-number-grid {
            display: flex;
            grid-gap: 0;

            .sequence-number {
              font-family: 'Nunito', serif;
              font-style: normal;
              display: inline-block;
              line-height: 100%;
              font-weight: 900;
            }
          }

          .sequence-numbers {
            position: relative;


            margin: 0px 5px 0px 5px;
            padding: 0 0.6rem 0 0.6rem;
            line-height: 0.5;
            .answer-text {
              text-align: center;
              font-size: 4rem;
              font-family: 'Nunito', serif;
              font-style: normal;
              display: block;
              margin-top: 40%;
              margin-bottom: 0;
              line-height: 100%;
              font-weight: 900;
            }

            .background {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 0;
              width: 100%;
              height: 100%;
              transition: 300ms background-color cubic-bezier(0.55, 0, 1, 0.45);
              background-color: rgba(68, 68, 68, 0.2);
              border-radius: 10px;
            }

            > :not(.background) {
              position: relative;
              z-index: 1;
            }

            &.unchecked .background {
              background-color: rgba(68, 68, 68, 0.2);
            }

            &.correct .background {
              background-color: #1f19;
            }

            &.incorrect .background {
              background-color: #f119;
            }

            &.incorrect {
              .answer-text {
                color: #fff;
              }
            }
          }

          padding: 35px 25px 35px 15px;
          @include responsive-bg('games/ShortCircuit/images/', 'modal-input.webp');

          div.answer-instructions {
            width: 100%;
            text-align: center;
            margin-top: 10px;

            position: absolute;
            left: 0;
            bottom: 0;
            padding-top: 10px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 0 0 15px 15px;

            p {

              font-size: 16px;
              font-weight: 900;
              color: #000;
              text-align: center;
              &.correct {
                color: green;
              }

              &.incorrect {
                color: red;
              }
            }


          }
        }

        .number-buttons {
          margin-top: 25px;
          margin-bottom: 25px;
          display: grid;
          grid-template-columns: repeat(10, 1fr);
          grid-gap: 5px;

          button {
            font-size: 24px;
            height: 40px;
            border-radius: 5px;
            -webkit-transition: all 0.4s ease-out;
            -moz-transition: all 0.4s ease-out;
            -ms-transition: all 0.4s ease-out;
            -o-transition: all 0.4s ease-out;
            transition: all 0.4s ease-out;
            border: 0;
            font-family: "Nunito", sans-serif;
            font-weight: 900;

            &:hover {
              cursor: pointer;
              background-color: #d3d3d3;
              box-shadow: inset 0px 0px 0px 4px #f3f3f3;
              box-sizing: border-box;
            }
          }
        }

        .short-circuit-modal-button {
          margin-right: 5px;
          font-size: 18px;
          border-radius: 5px;
          border: 3px solid #c3c3c3;
          padding: 10px 15px;
          -webkit-transition: all 0.4s ease-out;
          -moz-transition: all 0.4s ease-out;
          -ms-transition: all 0.4s ease-out;
          -o-transition: all 0.4s ease-out;
          transition: all 0.4s ease-out;
          font-family: "NunitoBlack", sans-serif;
          font-weight: 600;

          &:hover {
            cursor: pointer;
            background-color: #d3d3d3;
            box-shadow: inset 0px 0px 0px 4px #f3f3f3;
            box-sizing: border-box;
          }
        }

      }
    }
  }

  .binding {
    background-color: #fff;
    position: absolute;
    z-index: 20000;
    top: -80px;
    right: 10px;
    color: #000;
    font-weight: 700;
    font-size: 20px;
    list-style: none;
  }

  .grid {
    @include fluid-property('max-width', 1100px, 1200px, 80%, 66%);
    margin: 0 auto;
    margin-top: 10px;
    display: grid;
    @include fluid-property('grid-template-columns', 1100px, 1200px, repeat(6, minmax(30px, 100px)), repeat(6, minmax(30px, 120px)));
    grid-gap: 1.3rem;
    padding: 4.8em;
    justify-content: center;
    align-content: center;

    .play-button {
      min-height: 0;
      position: relative;

      &.correct.active {
        background-color: pink;
      }

      &:before {
        content: "";
        display: block;
        height: 0;
        width: 0;
        padding-bottom: calc(1 / 1 * 100%);
      }

      span {
        display: none;
        position: absolute;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 5rem;
        z-index: 99999;
        width: 200%;
        left: -29%;
        top: 10px;
        padding: 5px;
        transition: all 0.2s ease;
        background-size: auto;
        height: 100%;
      }

      img {
        position: absolute;
        top: 0;
        width: 100%;
        height: auto;
        display: inline-block;
        padding-bottom: 0px;
        overflow: hidden;
        border: 0px;
        background: transparent;
        outline: none;

        -webkit-transition: 0.2s ease-in-out;
        transition: 0.2s ease-in-out;
        @include grow-effect;
      }

      &.correct img, &.incorrect img {
        width: 100%;
      }

      &.correct {
        &:after {
          content: '';
          position: absolute;
          left: 25%;
          top: 23%;
          z-index: 10;
          width: 50%;
          height: 50%;
          background: url($cdn_url + 'games/ShortCircuit/images/medium/check.svg') no-repeat center center;
          background-size: contain;
          opacity: 1;
          transition: opacity 0.5s cubic-bezier(0.680, -0.550, 0.265, 1.550);
        }
      }

      &.incorrect {
        &:after {
          content: '';
          position: absolute;
          left: 25%;
          top: 23%;
          z-index: 10;
          width: 50%;
          height: 50%;
          background: url($cdn_url + 'games/ShortCircuit/images/images/incorrect-border.svg') no-repeat center center;
          background-size: contain;
          opacity: 1;
          transition: opacity 0.5s cubic-bezier(0.680, -0.550, 0.265, 1.550);
        }
      }

    }

    .hint-mode {
      transition: all 0.3s;

      &:not(.correct) {
        @include flash-effect();
      }

      &:active {
        @include push-effect-do();
      }

      &:hover {
        opacity: 1;
        animation: none;
      }
    }

    .play-button.hint-mode:not(.correct):not(.incorrect) {
      &:hover {
        cursor: pointer;
      }

      img {
        &:hover {
          @include grow-effect-do;
          cursor: pointer;
        }

        &:not(.play-disabled):not(.incorrect) {
          &:active {
            @include push-effect-do;
          }
        }

        &.incorrect:not(.play-disabled) {
          @include buzz-effect-do;
        }

        &.incorrect, .play-disabled {
          cursor: default;
        }
      }
    }
  }

  .play_timer {
    margin-top: 25px;
    min-height: 34px;
    height: 34px;
    position: absolute;
    left: 0;
    right: 0;
  }
}

.answerModal-enter-active, .answerModal-leave-active {
  transition: transform 1s ease-out;
}

.answerModal-leave /* .slide-
  fade-leave-active below version 2.1.8 */
{
  transform: translateY(-1200px);
}

.answerModal-enter, .answerModal-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateY(1200px);
}

.list-enter-active {
  opacity: 0;
  transition: opacity 10s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}
