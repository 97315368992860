.introduction {
  .splash {
    text-align: center;
    img {
      border-radius: 10px;
      max-width: 62vw;
      width: 100%;
      -webkit-box-shadow: 5px 5px 6px -2px rgba(0,0,0,0.67);
      box-shadow: 5px 5px 10px -2px rgba(0,0,0,0.5);

    }
  }
}
