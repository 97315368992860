.selector {
  min-width: 135vw;
  margin: 0 auto;
  h4 {
    text-align: center;
    margin: 0 0 2rem 0;
  }
  .vueperslides-backup {
    position: relative;
    top: -25vh;
  }
  .vueperslides__arrows--outside .vueperslides__arrow {
    @include image(40px, 40px);
    @include grow-effect();
    @include pixel-button($light-color, $dark-color);
    :hover {
      @include grow-effect-do();
    }
    padding: 0px 5px 0px 5px;
    width: 60px;
    height: 30px;

    opacity: 1;
    z-index: 1000;

    &.vueperslides__arrow--prev {
      left: 25vw;
      img {
        display:block;
        margin-top: -7px;
        margin-left: 3px;
        transform: rotate(180deg) scale(1.3);
      }
    }

    &.vueperslides__arrow--next {
      right: 25vw;
      img {
        margin-top: -7px;
        transform: scale(1.3);
      }
    }
  }

  .vueperslides__bullet {
    .default {
      display: none;
    }

    @include drop-filter();

    border-radius: 0;
    border: none;
    box-shadow: none;
    transition: 0.3s;
    width: 12px;
    height: 12px;
    padding: 10px;
    opacity: 0.8;
    background: url($cdn_url + 'bullet.png') no-repeat;
    background-size: contain;
  }

  .vueperslides__bullet--active {
    background: url($cdn_url + 'bullet-active.png') no-repeat;
  }
}


.vueperslide {
  background-size: cover;
  background-repeat: no-repeat;
  transition: .3s ease-in-out;
  border-radius: 10px;
  box-shadow: 5px 5px 10px -2px rgba(0, 0, 0, 0.5);
  padding: 0 !important;
  opacity: 0.7;
  top: 0;
  span {
    display: none;
  }

      .start-game {
        width: 100%;
        height: 100%;
        display: block;
        min-width: 280px;
        min-height: 200px;
        &:hover {
          cursor: pointer !important;
        }
      }


  pointer-events: none;
  cursor: default;

  transform: scale(0.8);
  &.vueperslide--active {
    pointer-events: all;
    opacity: 1 !important;
    transform: scale(1);
    &:hover {
      transform: scale(1.02);
    }
    filter: blur(0);
  }
}

.vueperslide, .vueperslide__image {
  background-position: top;
  padding: 0 !important;
}

.vueperslides__parallax-wrapper, .vueperslides__track {
  overflow: visible !important;
}

div.description {
  font-size: 1.2rem;
  line-height: 2.6rem;
  text-align: center;
  orphans: 3;
  font-weight: 500;
  padding: 20px;
  max-width: 70vw;
  position: absolute;
  z-index: 99999;
  margin: 0 auto;
  bottom: 5vh;
  vertical-align: top;
  @include pixel-button($light-color, $dark-color);
}