$break-small: 768px;
$break-medium: 1244px;

.sweet-sampler {
  .gameplay-container {
    transform: translate(-3000px, 0px);
  }

  .sweetsampler__question_container {
    transition: all 0.5s;
  }
}

.sweetsampler__container {
  width: 100vw;
  height: 85vh;
  background-size: contain;
  align-items: center;
  text-align: center;
  display: flex;
  flex-flow: column;
}

.sweetsampler__question_container {
  .row:first-child {
    padding-bottom: 40px;
    @media screen and (max-width: $break-small) {
      padding-bottom: 0;
    }

  }

  width: 87vw;
  height: 100vh;

  @include responsive-bg('games/SweetSampler/images/', 'board.webp');
  background-size: contain;
  margin: auto;
  background-repeat: no-repeat;
  max-width: 1224px;
  position: relative;
  padding: 40px 110px 60px 120px;

  display: flex;
  flex-flow: column;
  justify-content: center;
  background-position: center;

  @media screen and (max-width: 2000px) {
    padding: 40px 250px 70px 250px;
  }

  @media screen and (max-width: $break-medium) {
    padding: 40px 70px 70px 70px;
  }
  @media screen and (max-width: $break-small) {
    padding: 60px 50px 70px 50px;
  }
}

.sweetsampler__label_container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.sweetsampler__answer_container {
  @include modal-dropshadow();

  width: 77%;
  height: 70%;
  margin: 5% auto auto;
  max-width: 1024px;
  max-height: 700px;
  position: relative;
  z-index: 5000;
  padding: 20px 60px 40px 60px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  background-position: center;
  border-radius: 5px;
  background-image: linear-gradient(#f1e5e5, #d0d0d0);

  @media screen and (max-width: $break-medium) {
    padding: 20px 40px 40px 40px;
  }
  @media screen and (max-width: $break-small) {
    padding: 20px 30px 80px 30px;
  }

  &:not(.reveal-answers) {
    .bg-green:not(.sweetsampler__candy-command), .bg-red {
      background-color: #a2a2a2 !important;
    }
  }
}

.sweetsampler__user_answer_container {
  width: 100%;
  height: 50%;
  background-repeat: no-repeat;
  max-width: 1024px;
  position: relative;
  padding: 10px;
  margin: 5% auto 15px;
  color: black;
  background-position: center;
  box-shadow: inset 6px 6px 12px #444, inset -6px -6px 36px #666;
  border-radius: 5px;
  background-image: linear-gradient(#898989, #616161);

  div.label {
    font-size: 1.2rem;
    font-family: "NunitoBlack", sans-serif;
  }

  @media screen and (max-width: $break-medium) {
    div.label {
      font-size: 1.1rem;
    }
  }
  @media screen and (max-width: $break-small) {
    margin-top: 5px;
    div.label {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: $break-medium) {
    padding: 20px 80px 40px 80px;
  }
  @media screen and (max-width: $break-small) {
    padding: 80px 60px 80px 60px;
  }
}

.sweetsampler__candy-gray {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 60px;
  width: 60px;
  @media screen and (max-width: $break-medium) {
    height: 50px;
    width: 50px;
  }
  @media screen and (max-width: $break-small) {
    height: 1.6rem;
    width: 1.6rem;
  }
}

.sweetsampler__candy-command {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 80px;
  width: 80px;

  @media screen and (max-width: $break-medium) {
    height: 70px;
    width: 70px;
  }
  @media screen and (max-width: $break-small) {
    height: 92%;
    width: 92%;
    text-align: center;

    line-height: 0.1rem;
    font-size: 0.7rem;
  }
}

.sweetsampler__progress {
  margin: 10px 100px 10px 100px;
  padding: 0 20px 0 20px;
  width: 80vw;
  position: absolute;
  bottom: -35px;
  max-width: 860px;
}

.sweetsampler__divot {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 160px;
  display: flex;
  align-items: center;

  @media screen and (max-width: $break-small) {
    height: 90px;
  }
}

.sweetsampler__candy {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: all 0.2s ease-out;
  height: 120px;
  width: 100%;
  @media screen and (max-width: $break-medium) {
    height: 80px;
  }
  @media screen and (max-width: $break-small) {
    height: 50px;
  }

  &:active {
    transform: scale(1.1);
  }
}


.sweetsampler__action {
  top: 0;
  bottom: 0;
  margin: auto;
  left: calc(100% + 0.4vw);
  width: 20vw;
  height: 20vw;
  text-align: center;
}

.sweetsampler__action-button {
  border: 0;
  @include responsive-bg-repeat('games/SweetSampler/images/', 'red_push_btn.webp');
  padding: 0;
  width: 135px;
  height: 106px;
  border-radius: 100%;
  cursor: pointer;
  top: calc(25% - 0.9vw);
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}


.q-linear-progress {

  border: 2px solid #fff;
  padding: 4px 8px 4px 4px;
  min-height: 50px;

  .q-linear-progress__model {
    left: 10px;
    max-height: 25px !important;
    max-width: 97.5%;
    background-color: #fff;
    top: 23%;
    transform: translateY(-23%);
  }
}

.sweetsampler__user_answer_row {
  padding-left: 1.5rem;
}

.sweetsampler__user_answer_wrapper {
  margin-right: 1rem;
}

.answerCorrect {
  color: #003a08;
}

.answerIncorrect {
  color: #d61b1b;
}